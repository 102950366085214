@import "newVariables";
@import "_mixins";


.site-header-spacer {
  // This rule MUST exactly match the site-header - as it pushes the site content down below fixed header
  @media (max-width: @screen-sm) {
    height: 0;
    overflow: hidden;
  }
  height: 80px;
}

.site-header {
  @media (max-width: @screen-sm) {
    position: static; //need to allow scrolling when menu is open on mobile.
  }
  position: fixed;
  .sr-only {
    color: #fff;
  }
  body.prp & {
    position: static;
  }

  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 300ms, box-shadow 300ms;
  a {
    color: @color-c21-gold;
    text-decoration: none;
    &.ftsHighlight,
    &.ftsLocation,
    &.ftsLocationListings,
    &.ftsLocationAgents,
    &.ftsLocationOffices {
      color: @color-c21-gold-ada;
      &:hover, &:focus {
        color: #252526 !important;
      }
    }
    &:hover, &:focus {
      color: @obsessed-grey !important;
    }
  }
  .header-top-bar {
    display: none; //temporary hack -- remove when interior nav complete
  }
  .header-main-bar {
    font-family: @subhead-font;
    background-color: @obsessed-grey;
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    @media (min-width: @screen-sm) {
      height: 80px;
      align-items: center;
    }
    @media (max-width: @screen-phone-max) {
      height: 111px;
      .mobile-container {
        min-height: 100px;
      }
    }
    .logo {
      vertical-align: top;
      height: 60px;
      display: inline-block;
      a {
        .pngIconFallback(0, 0, '/images/portal/Century21-logo');
        display: block;
        height: inherit;
        width: 127px;
        text-indent: -9999px;
        margin-right: 15px;
        margin-left: 19px;
        margin-top: 3px;
        @media (min-width: 1025px) {
          width: 177px;
        }
      }
    }
    .navbar-toggle {
      display: none;
      @media (max-width: @screen-phone-max) {
        position: absolute;
        left: -100%;
        top: -100%;
        + label {
          font-size: 25px;
          margin-top: 24px;
          padding-right: 15px;
          -webkit-font-smoothing: antialiased;
          cursor: pointer;
        }
        + label:after {
          color: @color-c21gold;
          content: "☰";
        }
      }
    }
    .navbar-toggle:checked ~ .header-main-nav {
      display: inline-block;
      z-index: 5;
      position: absolute;
      width: 100%;
      margin-top: 100px;

      @media (min-width: @screen-xxs) and (max-width: @screen-sm) {
        margin-top: 111px;
      }
    }
    .header-main-nav {
      @media (min-width: @screen-sm) {
        order: 1; //Swap order on desktop
      }

      &.search-open {
        @media (min-width: @screen-sm) {
          display: none;
        }
      }

      .sister-sites {
        position: relative;
        .deco-logo {
          width: 75px;
          height: 75px;
          position: absolute;
          right: 15px;
          top: -39px;
          svg {
            fill: @color-c21gold;
          }
        }
        .nav {
          padding: 15px;
          a {
            font-family: @subhead-font;
            text-transform: uppercase;
            font-size: 14px;
            display: block;
            padding: 20px 0;
          }
        }
        .header-phone {
          font-size: 20px;
          font-family: @headline-font;
        }
        .social-icons {
          margin-left: auto;
          .social-icon {
            &:last-child {
              margin-right: 0;
            }
          }

          .social-icon-facebook {
            .pngIconFallback(0,0,'/images/portal/ionicons-facebook-gold');
          }
          .social-icon-google {
            .pngIconFallback(0,0,'/images/portal/ionicons-google-gold');
          }
          .social-icon-instagram {
            .pngIconFallback(0,0,'/images/portal/ionicons-instagram-gold');
            padding-right: 0;
          }
          .social-icon-twitter {
            .pngIconFallback(0,0,'/images/portal/ionicons-twitter-gold');
          }
          .social-icon-youtube {
            .pngIconFallback(0,0,'/images/portal/ionicons-youtube-gold');
          }
        }
        .nav-bottom {
          display: flex;
          padding: 0 15px 15px 15px;
          align-items: center;
        }
      }
    }
    .right-nav {
      margin-left: auto;
      display: flex;
      margin-right: 19px;
      align-items: center;
      padding-top: 26px;
      @media (min-width: @screen-sm) {
        padding-top: 0;
        order: 2; //Swap order on desktop
      }
      .nav-item {
        margin-right: 1.5em;
        a {
          color: @color-c21gold !important;
          &:hover, &:focus {
            color: @white;
          }
        }
        svg {
          fill: @color-c21gold;
        }
        &:hover, &:focus {
          svg {
            fill: @white;
          }
        }
      }
      .header-account-button {
        height: 12px;
        width: 12px;
        cursor: pointer;
        position: relative;

      }
      .header-chat {
        height: 12px;
        width: 12px;
        cursor: pointer;
      }
      .header-language-chooser {
        cursor: pointer;
        color: @color-c21gold;
        //we have to override search.css here...
        border: 1px solid @color-c21gold;
        border-radius: 0;
        background-color: transparent;
        font-size: 11px;
        padding: .25em .75em;
        @media (max-width: @screen-phone-max) {
          display: none;
        }
        &.btn {
          letter-spacing: 1px;
        }
        &:hover, &:focus {
          background-color: @color-c21gold;
          color: @obsessed-grey !important;
        }

        .header-language-options {
          display: none;
          font-size: 11px;
          font-family: @subhead-font;
          line-height: 25px;
          color: @redesign-black;
          width: 100%;
          background-color: @obsessed-grey;
          background-image: url(/images/portal/LanguageOverlay-375.png);
          background-size: cover;
          padding: 20px;
          z-index: 10000;
          margin: 0;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 100vh;
          letter-spacing: .05em;
          .animated-fast();
          @media(max-width: @screen-phone-max) {
            width: inherit;
          }
          @media(min-width: @screen-md) {
            background-image: url(/images/portal/LanguageOverlay-1440.png);
          }
          .h1 {
            color: @white;
            font-size: 24px;
            letter-spacing: 2.28px;
            font-weight: normal;
          }
          .header-language-inner {
            background: @color-c21gold;
            text-align: left;
            border-left: 54px solid @color-c21darkgold;
            padding: 8px 0;
            @media (min-width: @screen-tablet-min) {
              border-left: 85px solid @color-c21darkgold;
            }
          }
          .header-language-wrap {
            width: 470px;
            margin: auto;
            @media (min-width: @screen-tablet-min) {
              width: 510px;
            }
          }
        }
        .header-language-title {
          line-height: 32px;
          text-align: left;
          border-bottom: 1px solid #596064;
          text-transform: none;
          font-weight: normal;
          strong {
            color: @redesign-black;
            font-size: 16px;
          }
        }
        a {
          color: @redesign-black;
          display: inline-block;
          width: 128px;
          text-transform: none;
          font-weight: normal;
          &:hover {
            color: @black !important;
          }
        }
        .header-language-active {
          color: @black;
          width: 117px;
          &:before {
            content: '>';
            display: inline-block;
            font-size: 14px;
            padding-right: 5px;
          }
        }
        .header-language-usa {
          .pngIconFallback(0,0, '/images/portal/nav-lang-usa');
          padding: 0 12px 35px 84px;
          margin-left: -72px;
        }
        .header-language-global {
          .pngIconFallback(0,0, '/images/portal/nav-lang-globe');
          padding: 0 12px 6px 84px;
          margin-left: -72px;
        }
        &.c21-dropdown-open {
          .header-language-options {
            display: flex;
          }
        }
      }
      .header-phone {
        font-family: @headline-font;
        font-size: 13px;
      }
    }
    .fha-link {
      position: absolute;
      top: 90px;
      right: 14px;
      font-size: 9px;
      color: #FFF !important;
      display: none;

      @media (max-width: @screen-phone-max) {
        display: block;
      }
    }

    nav {
      display: inline-block;
      font-weight: 400;
      @media (max-width: @screen-phone-max) {
        position: relative;
        background-color: @obsessed-grey;
        width: 100%;
        display: none;
        margin-top: 40px;
      }

      .header-mega {
        display: inline-block;
        padding: 31px 0 26px 0;
        @media(max-width: @screen-phone-max) {
          display: table;
          width: 100%;
          padding: 20px 18px 15px 15px;
          background-color: #3d3935;
        }
        &.more-menu-toggle {
          @media (max-width: @screen-phone-max) {
            display: none;
          }
          .header-mega-label {
            color: @color-c21gold;
          }
        }
        .header-mega-label {
          display: inline-block;
          text-transform: uppercase;
          position: relative;
          padding-right: 2px;
          margin-left: 2px;
          font-size: 11px;
          letter-spacing: 1.6px;
          color: @white;
          cursor: pointer;
          &:hover, &:focus {
            color: @color-c21-gold;
          }
          @media(max-width: @screen-phone-max) {
            font-size: 14px;
          }
          @media (min-width: @screen-tablet-min){
            padding-right: 10px;
            margin-left: 10px;
          }
        }
        .header-mega-menu {
          display: none;
          width: 100%;
          position: absolute;
          left: 0;
          top: 79px;
          min-height: 220px;
          z-index: 1000001;
          box-shadow: 0 3px 5px -2px rgba(0, 0, 0, .5);
          @media(min-width: @screen-phone-max) {
            .animated-fast();
          }
        }
        &.c21-dropdown-open {
          color: @white;
          background-color: @obsessed-grey;
          @media (min-width: @screen-sm) {
            background: @white;
          }
          @media(max-width: @screen-phone-max) {
            outline: none;
          }
          .header-mega-label {
            color: @white;
            &:hover, &:focus {
              color: @color-ada-gold;
              @media(max-width: @screen-phone-max) {
                color:  @color-c21-gold;
              }
            }
            @media (min-width: @screen-sm) {
              color: @obsessed-grey;
              font-weight: bold;
            }
          }
          .header-mega-menu {
            display: block;
            @media(max-width: @screen-tablet-max) {
              li:last-child {
                border-left: none;
                p {
                  width: 60%;
                  display: inline-block;
                  float: left;
                }
                button.findAgent {
                  width: 30%;
                  display: inline-block;
                  margin-top: 0;
                }
              }
            }
            @media(max-width: @screen-phone-max) {
              position: static;
              box-shadow: none;
              animation: none !important;
              animation-name: none !important;
              animation-duration: initial;
              animation-fill-mode: initial;
              -webkit-transition: none !important;
              -moz-transition: none !important;
              -o-transition: none !important;
              transition: none !important;
              .header-mega-categories {
                text-transform: uppercase;
                .header-mega-wrapper {
                  h4 {
                    font-weight: 200;
                  }
                }
              }
              li:last-child {
                max-height: inherit;
                div {
                  width: 250px;
                  text-transform: none;
                  p {
                    padding-left: 20px;
                    width: 100%;
                  }
                }
              }
            }
          }

          .header-mega-categories {
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 0;
            display: block;
            a {
              &:hover, &:focus {
                color: @color-c21-gold-ada;
              }
            }
            @media (min-width: @screen-desktop-min){
              padding-left: 30px;
            }
            > li {
              display: inline-block;
              vertical-align: top;
              height: inherit;
              min-width: 200px;
              @media(max-width: @screen-phone-max) {
                display: block;
                height: auto;
                padding-left: 20px;
              }
              .header-mega-wrapper {
                padding: 20px 20px 0 0;
                @media (max-width: @screen-tablet-max) {
                  @media (min-width: @screen-tablet-min) {
                    padding: 20px 15px;
                  }
                }
                a.btn-gold {
                  line-height: 40px;
                  border-radius: 0;
                  min-width: 142px;
                  display: inline-block;
                  margin-bottom: 10px;
                  text-align: center;
                }
                .franchise-contact {
                  margin: 10px 0;
                }
                .career-contact {
                  margin-top: 10px;
                }
                h4 {
                  font-weight: 100;
                  text-transform: uppercase;
                  font-size: 16px;
                  color: #313131;
                  display: none;
                }
                p {
                  display: none;
                  font-weight: 200;
                  color: #858585;
                  padding-top: 10px;
                  font-size: 14px;
                }
                img {
                  height: 160px;
                  width: 231px;
                }
                .header-mega-subcategories {
                  display: block;
                  padding-left: 0;
                  list-style-type: disc;
                  margin-top: 1em;
                  margin-bottom: 1em;
                  li {
                    font-size: 14px;
                    display: list-item;
                    list-style-type: none;
                    text-transform: initial;
                    line-height: 2;
                    font-weight: 400;
                    border-left: none;
                    padding-bottom: 0;
                    padding-top: 0;
                    background-color: transparent;
                    border-top: none;
                    position: relative;
                    @media (min-width: @screen-sm) {
                      font-size: 13px;
                      font-family: @body-font;
                    }
                    &.header {
                      @media (max-width: @screen-phone-max) {
                        color: @white;
                        .loc-display-name {
                          color: @white;
                        }
                      }
                      font-size: 10px;
                      color: @redesign-black;
                      text-transform: uppercase;
                      letter-spacing: 1px;
                      .loc-display-name {
                        display: inline-block;
                        font-size: 10px;
                      }
                    }
                    a {
                      @media (max-width: @screen-phone-max) {
                        color: @color-c21-gold;
                        .loc-display-name {
                          color: @light-grey;
                        }
                      }
                      &.bug {
                        &.hover, &.focus {
                          color: @black !important
                        }
                      }
                    }
                    .bug {
                      color: @color-c21grey !important;
                      background-color: @redesign-gold;
                      border-radius: 20px;
                      font-weight: 200;
                      position: absolute;
                      left: 133px;
                      height: 19px;
                      line-height: 19px;
                      cursor: pointer;
                      top: 4px;
                      padding: 0px 6px;
                      font-size: 11px;

                      @media (max-width: @screen-phone-max) {
                        top: 8px;
                        left: 153px;
                      }
                    }
                    .loc-display-name {
                      display: block;
                      color: @text-grey;
                      margin-top: -9px;
                      font-size: 10px;
                      @media (min-width: @screen-sm) {
                        font-size: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
          .mortgage {
            li{
              .header-mega-wrapper {
                img {
                  margin-top: 10px;
                }
                .header-mega-subcategories {
                  li {
                    line-height: 2;
                  }
                }
              }
            }
          }

          .franchise {
            li {
              .header-mega-wrapper {
                img {
                  margin-top: 10px;
                }
                .header-mega-subcategories {
                  li {
                    line-height: 2;
                  }
                }
              }
            }

            li:last-child {
              .header-mega-wrapper {
                button.findAgent {
                  padding: 10px 30px;
                }
              }
            }
          }
        }
      }
    }
    #dropdown-homepage-container {
      .search-toggle {
        width: 15px;
        height: 15px;
        display: none;
        position: absolute;
        right: 100px;
        top: 0;
        margin: 35px 0 0 0;
        .pngIconFallback(0, 0, '/images/portal/icon_search');
        .search-label {
          display: none;
        }
        @media (min-width: @screen-sm) {
          display: block;
        }
        @media (min-width: 1255px) {
          width: 249px;
          background-position: right, 0;

          .search-label {
            display: block;
            font-size:11px;
            color: @color-c21-gold;
            text-transform: uppercase;
            border-bottom: 1px solid @color-c21-gold;
            text-align: center;
            padding-right: 23px;
          }
        }

        @media (min-width: 1025px) {
          right: 170px;
        }

        @media screen and (min-width: 1025px) and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
          right: 185px;
        }
      }
    }

    #dropdown {
      margin: 29px 0 0 0;
      width: auto;
      position: absolute;
      right: 68px;
      top: 0;
      left: auto;

      @media (min-width: @screen-sm) {
        right: 150px;
        #dropdownSearchBox {
          width:0;
          transition: all 0.5s ease-in-out;
        }
        &.active {
          #dropdownSearchBox {
            width: calc(~"100vw - 330px");
          }
        }
      }
      @media (min-width: 1025px) {
        &.active {
          #dropdownSearchBox {
            width: calc(~"100vw - 537px");
          }
        }
        right: 320px;
      }
      @media (min-width: 1160px) {
        &.active {
          #dropdownSearchBox {
            width: 632px;
          }
        }
        right: 320px;
      }

      @media screen and (min-width: 1025px) and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
        right: 184px;
      }

      .ftsClear {
        position: absolute;
        top: 6px;
        right: 37px;
        // display: none; always show in header
        outline: 0;
        width: 15px;
        height: 15px;
        border: none;
        line-height: 15px;
        padding: 0;
        cursor: pointer;
        .pngIconFallback(0, 0, '/images/portal/black-clear-search');
        background-size: 15px 15px;
        &.showing {
          display: inline-block;
        }
      }

      #behindSearchText {
        color: #595959;
        // CR-55 Fix Safari bug. It renders disabled text bad.
        // See: http://stackoverflow.com/questions/14547632/why-is-text-in-disabled-form-elements-not-rendered-in-black-in-safari
        -webkit-text-fill-color: #595959;
        background-color: #fff;
      }

      @media (max-width: @screen-phone-max) {
        position: absolute;
        top: 63px;
        margin: 0;
        max-width: none;
        right: 15px;
        left: 13px;
        #dropdownSearchBox {
          width: 100%;
          box-shadow: none;
          border: 0;
          input.searchText {
            border: 0;
            box-shadow: none;
            background: transparent;
          }
        }

        #dropdownResults {
          left: -13px;
          right: -15px;
        }

        &.expanded {
          #dropdownSearchBox {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            input.searchText {
              // Line should not appear underneath button on right
              // Line should not cause input to change position
              // If you change or move these lines, please check these things
              border-bottom: 1px solid #EAEAEA;
              border-top: 1px solid #fff;
            }
          }
        }
      }
      .searchText {
        top: 0;
        left: 0;
        width: calc(~"100% - 26px");
        font-size: 12px;
        line-height: 26px;
        height: 26px;
        box-sizing: border-box;
        padding-left: 8px;
        background: @white;

        @media screen and (max-width: @screen-tablet-max) and (min-width: @screen-tablet-min) {
          font-size: 11px;
        }

      }
    }
    #dropdownSearchBox {
      position: relative;
      background: #fff;
      width: 210px;
      height: 27px;
      border-radius: 0;
      @media (min-width: @screen-phone-max) {
        @media (max-width: @screen-tablet-max) {
          width: 175px;
        }
      }
      @media (min-width: @screen-desktop-min) {
        width: 275px;
      }
      overflow: hidden;
      label {
        position: absolute;
        left: 6px;
        top: 6px;
        font-weight: 300;
        color: @color-c21grey; // #333;
      }
      #free-text-search-input {
        border: none;
        height: 26px;
        padding: 0 0 0 6px;
        position: absolute;
        width: 50px;
      }
      #searchButton {
        position: absolute;
        right: 0;
        top: 0;
        .pngIconFallback(center, center, '/images/portal/find');
        background-color: @redesign-gold;
        border: none;
        height: 27px;
        width: 32px;
        cursor: pointer;
        background-size: 15px;
        &::before {
          content: "";
        }
      }
    }

    .c21-dropdown-open {
      .arrow_box {
        position: relative;
        background: #ffffff;
      }
      .arrow_box:after {
        top: -19px;
        right: 16px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
      }
      #account-dropdown {
        position: absolute;
        min-width: 300px;
        width: 100%;
        display: block;
        pointer-events: none;
        top: 40px;
        right: -20px;
        max-width: 320px;
        z-index: 999;
        border-radius: 3px;
        -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);

        .zapLoginText {
          a {
            color: @color-c21-gold-ada !important;
          }
        }

        #HeaderSearchSaved {
          display: none;
          vertical-align: middle;
          margin: auto;
          pointer-events: auto;
          padding: 15px;
          text-align: center;
          .saved-search-title {
            font-size: 22px;
            color: @slate;
            border-bottom: 1px solid #ccc;
            padding: 10px 10px 15px 10px;
            background: url(/images/icons/check.png) 36px 10px no-repeat;
          }
          .saved-search-info {
            font-size: 16px;
            padding: 20px 10px 18px 10px;
            line-height: 24px;
            color: @very-dark-grey;
          }
          .saved-search-name {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        #HeaderLoggedOff {
          display: table-cell;
          vertical-align: middle;
          margin: auto;
          pointer-events: auto;
          padding: 15px;
          text-align: center;
          h3 {
            padding-bottom: .75em;
            text-align: center;
            font-family: @body-font;
            font-size: 21px;
            letter-spacing: .14px;
            color: @redesign-black;
          }
          button:nth-child(3) {
            margin-right: 0;
          }

          #account-my-favorites, #account-create-account {
            padding: 10px 0px;
            font-size: 14px;
            width: 48%;
            font-family: @body-font;
            text-transform: none;
            font-weight: normal;
          }

          #account-sign-in {
            padding: 10px 0px;
            font-size: 14px;
            width: 100%;
            margin-top: 10px;
            font-family: @body-font;
            text-transform: none;
          }
        }
        #HeaderLoggedIn {
          display: none;
          vertical-align: middle;
          margin: auto;
          pointer-events: auto;
          padding: 15px;
          text-align: center;

          #account-welcome-msg {
            display: block;
            color: @color-ada-gold !important;
            font-size: 23px;
            text-decoration: underline;
            padding: 5px 0px 15px 0px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          #account-my-favorites,
          #account-my-saved-searches {
            padding: 10px 0px;
            font-size: 14px;
            width: 100%;
          }

          #account-sign-out {
            padding: 10px 0px;
            font-size: 14px;
            width: 100%;
            margin-top: 10px;
            font-family: @body-font;
            text-transform: none;
          }
        }
      }
    }
    #account-dropdown {
      display: none;
    }
  }
}

/* Meganav Redux */
.site-header {
  .header-main-bar {
    z-index: 99;
    nav {
      .header-mega.more-menu {
        @media (max-width: @screen-phone-max) {
          display: none;
        }
      }
      .header-mega.c21-dropdown-open {
        .menu-1.header-mega-menu {
          background-color: @white;
          @media(min-width: @screen-tablet-min) and (max-width: @screen-tablet-max){
            max-height: 340px;
          }
          @media(min-width: @screen-md) {
            display: flex;
          }
          .header-mega-categories {
            > li {
              width: 200px;
            }
            > li:nth-child(3){
              @media(max-width: @screen-phone-max) {
                .header-mega-wrapper{
                  .header-mega-subcategories{
                    border-top: 1px solid @grey;
                    padding: .7em 0;
                  }
                }
              }

              @media(max-width: @screen-tablet-max) {
                .header-mega-wrapper{
                  .header-mega-subcategories{
                    padding-bottom: .7em;
                  }
                }
              }
            }
          }
          @media(max-width: @screen-phone-max) {
            background: none;
            .header-mega-wrapper {
              padding: 1px 20px;
            }
            .header-mega-categories {
              >li:first-child {
                min-height: inherit;
              }
              background-image: none;
              >li:nth-child(2){
                position: relative;
                left: 0;
                top: -7px;
              }
              > li:nth-child(3) {
                left: 0;
                position: relative;
              }
            }
          }
          @media(min-width: @screen-tablet-min) {
            .header-mega-categories {
              display: flex;
              width: calc(~"100% - 435px"); //accounting for 50th graphic
              > li:first-child {
                min-height: 331px;
                width: 235px;
              }
              > li:nth-child(3) {
                left: -200px;
                top: 125px;
                position: relative;
              }

              > li:nth-child(4) {
                left: -185px;
                position: relative;
              }
            }
          }
          @media(min-width: @screen-desktop-min) {
            .header-mega-categories {
              > li:nth-child(4) {
                left: -150px;
              }
            }
          }
        }
        .menu-2.header-mega-menu {
          background-color: @white;
          @media(min-width: @screen-md) {
            display: flex;
          }

          .menu-cta {
            .cta-text {
              display: none;
            }
            @media(min-width: @screen-sm) {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: @color-ada-gold;
              background-image: url(/images/portal/menu-started-bg.jpg);
              background-size: cover;
              background-repeat: no-repeat;
              width: 215px;
              height: 247px;
              .cta-text {
                display: block;
                font-family: @subhead-font;
                color: @white;
                font-size: 22px;
                font-weight: bold;
                line-height: .78;
                padding-bottom: 1em;
                span {
                  display: block;
                  padding-left: 1.25em;
                }
              }
              .btn-gold {
                background: @obsessed-grey;
                color: @white !important;
                &:hover, &:focus {
                  background: @color-c21grey;
                }
              }
            }
          }
          .header-mega-categories {
            @media(min-width: @screen-sm) {
              > li:first-child {
                width: 232px;
                flex-shrink: 0;
              }
              > li:nth-child(2) {
                min-width: 194px !important;
                padding-left: 16px;
                flex-shrink: 1;
              }
            }

            @media(min-width: @screen-tablet-min) {
              display: flex;
              width: calc(~"100% - 435px"); //accounting for 50th graphic
            }
            > li:last-child {
              padding-bottom: 0;
              margin-top: 0;
              .header-mega-wrapper {
                padding: 20px;
                p {
                  margin-right: 190px;
                  max-width: 730px;
                  width: auto;
                  float: none;
                }
                img {
                  display: none;
                }

              }
            }

          }

          @media(max-width: @screen-phone-max) {
            background: none;
            .header-mega-categories {
              background-image: none;
              > li:nth-child(3) {
                left: 0;
                top: 0;
                position: inherit;
              }
              > li:last-child {
                width: auto;
                margin-top: 0;
                padding: 20px;
                max-height: inherit;
                .header-mega-wrapper {
                  padding: 0;
                  width: auto;
                  p {
                    padding-left: 0;
                    padding-bottom: 20px;
                    margin-right: 0;
                  }
                }
                a {
                  display: block;
                  position: inherit;
                  right: 0;
                  top: 0;
                  margin-top: 0;
                }
              }
            }
          }
        }
        .menu-3.header-mega-menu {
          background-color: #fff;
          @media(min-width: @screen-phone-max) {
            .header-mega-categories {
              > li:last-child {
                .header-mega-wrapper {
                  padding: 20px;
                }
              }
            }
          }
          @media(max-width: @screen-phone-max) {
            background: none;
            .header-mega-categories {
              background-image: none;
              > li:nth-child(3) {
                left: 0;
                top: 0;
                position: inherit;
              }
              > li:last-child {
                width: auto;
                margin-top: 0;
                padding: 20px;
                .header-mega-wrapper {
                  /*padding: 0;*/
                  width: auto;
                  p {
                    padding-left: 0;
                    padding-bottom: 20px;
                  }
                  a {
                    display: inline-block;
                    position: inherit;
                    right: 0;
                    top: 0;
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
        .menu-4.header-mega-menu {
          background-color: #fff;
          .header-mega-categories {
            .header-mega-wrapper.no-header {
               margin-top: 7px !important;
            }
          }
          @media(min-width: @screen-phone-max) {
            .header-mega-categories {
              > li:last-child {
                padding-bottom: 0;
                .header-mega-wrapper {
                  width: auto;
                  margin: 0 auto;
                  padding: 10px 20px;
                  /*height: 105px;*/
                  .header-mega-sub:first-child {
                    border-right: 1px solid #7a7a7a;
                    padding-right: 20px;
                  }
                  .header-mega-sub:last-child {
                    padding-left: 20px;
                    a {
                      left: 30%;
                    }
                  }
                  .header-mega-sub {
                    width: 45%;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    height: 100%;
                    p {
                      display: inline-block;
                      float: none;
                      font-size: 12px;
                      text-align: center;
                      width: 100%;
                    }
                    a {
                      display: block;
                      position: absolute;
                      bottom: -2px;
                      left: 26%;
                      top: inherit;
                      right: inherit;
                      vertical-align: middle;
                      width: 40%;
                      max-width: 350px;
                      margin-top: 0;
                    }
                    a:first-child {
                      border-right: 1px solid @grey;
                    }
                  }
                }
              }
            }
          }
          @media(min-width: @screen-tablet-max) {
            .header-mega-categories {
              > li:last-child {
                .header-mega-wrapper {
                  box-sizing: border-box;
                  height: auto;
                  .header-mega-sub {
                    width: 47%;
                    min-height: inherit;
                    p {
                      width: 63%;
                      font-size: 12px;
                      display: inline-block;
                      text-align: left;
                    }
                    a {
                      top: 50%;
                      margin-top: -20px;
                      display: inline-block;
                      left: inherit;
                      position: absolute;
                      right: 0;
                      bottom: inherit;
                      max-width: 135px;
                      margin-right: 10px;
                    }
                  }
                  .header-mega-sub:last-child {
                    a {
                      left: inherit;
                      margin-right: -10px;
                    }
                  }
                }
              }
            }
          }
          @media(max-width: @screen-phone-max) {
            background: none;
            .header-mega-categories {
              background-image: none;
              > li:nth-child(3) {
                left: 0;
                top: 0;
                position: inherit;
              }
              > li:last-child {
                margin-top: 0;
                /*padding: 20px;*/
                max-height: inherit;
                width: auto;
                .header-mega-wrapper {
                  /*padding: 0;*/
                  width: auto;
                  p {
                    padding-left: 0;
                    padding-bottom: 20px;
                  }
                  a {
                    display: inline-block;
                    position: inherit;
                    right: 0;
                    top: 0;
                    margin-top: 0;
                  }
                  .header-mega-sub{
                    width: auto;
                  }
                  .header-mega-sub:first-child {
                    border-bottom: 1px solid #7a7a7a;
                    padding-bottom: 20px;
                  }
                }
              }
            }
          }
        }
        .menu-more.header-mega-menu {
          background-color: @obsessed-grey;
          top: 0;
          left: 171px;
          min-height: 80px;
          height: 80px;
          box-shadow: none;
          width: 400px;
          display: flex;
          align-items: center;
          @media (min-width: @screen-desktop-min) {
            left: 217px;
            width: 415px;
          }
          @media only screen
            and (min-device-width: 768px)
            and (max-device-width: 1024px)
            and (orientation: landscape)
            and (-webkit-min-device-pixel-ratio: 1) {
              left: 167px;
          }

          .more-nav {
            padding-left: 5px;
            padding-top: 5px;
            a {
              text-transform: uppercase;
              padding-right: 7px;
              margin-left: 7px;
              font-size: 11px;
              letter-spacing: 1.6px;
              color: @color-c21-gold !important;
              @media (min-width: @screen-desktop-min) {
                padding-right: 9px;
              }
              &:hover, &:focus {
                color: @white !important;
              }
            }
          }
        }

        .anniv-promo {
          display: none;
          width: 435px;
          padding: 25px 40px;
          flex-shrink: 0;
          background-color: @redesign-black;
          background-image: url(/images/portal/50th-menu-bg.jpg);
          background-size: cover;
          color: @white;
          @media (min-width: @screen-md) {
            display: flex;
            flex-direction: column;
          }
          .anniv-top {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: bold;
            a {
              color: @white;
              &:hover, &:focus {
                color: @white !important;
              }
            }
            img {
              display: block;
              margin-top: 1.5em;
              max-width: 180px;
              height: auto;
            }
          }
          .anniv-bottom {
            font-size: 22px;
            letter-spacing: 2px;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: auto;
            padding-top: 1em;
            span {
              display: block;
              padding-left: 1.5em;
            }
          }
        }
      }
    }
  }
}

body {
  &#pdp {
    .site-header {
      position: relative;
    }
    #site-footer {
      //Fix for language modal in footer on pdp
      .language-chooser {
        .language-options {
          .close {
            top: 90px;
          }
        }
      }
    }
  }
  &.modal-open {
    #site-footer {
      z-index: 3;
    }
  }
}
