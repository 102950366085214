@c21gold: #beaf87;

.rounded(@radius: 2px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
                               left bottom,
                               left top,
                               color-stop(0, @start),
                               color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
                                  @start,
                                  @stop);
  background: -moz-linear-gradient(center bottom,
                                   @start 0%,
                                   @stop 100%);
  background: -o-linear-gradient(@stop,
                                 @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}

.pngIconFallback(@x, @y, @filename) {
  background: url('@{filename}.png');
  background-image:  -webkit-linear-gradient(transparent, transparent),
          url('@{filename}.svg');
  background-image:  -o-linear-gradient(transparent, transparent),
          url('@{filename}.svg');
  background-image:  -moz-linear-gradient(transparent, transparent),
          url('@{filename}.svg');
  background-image: url('@{filename}.svg'),
    linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: @x, @y;
}

.tabletFontSize() {
  @media (min-width: @screen-phone-max) {
    @media (max-width: @screen-tablet-max) {
      letter-spacing: 0.05em;
      font-size: 10px;
    }
  }
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.meganavBackground(@filename) {
  background-image: url('/images/meganav/768_@{filename}.png');
  background-position: right top;
  background-position-x: right;
  background-size: contain;
  background-repeat: no-repeat;
  @media(min-width: @screen-tablet-max) {
    background-image: url('/images/meganav/1024_@{filename}.png');
  }
}

.contentPageHeroImage(@filename) {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  height: 266px;
  width: 100%;
  display: block;
  top: 80px;
  position: absolute;
  background-position: center center;
  background-color: #000;
  background-image: url('@{filename}_1366.jpg');
  @media(min-width: @screen-xxl) {
    background-image: url('@{filename}_1900.jpg');
  }
  @media(max-width: @screen-desktop-min) {
    background-image: url('@{filename}_1024.jpg');
  }
  @media(max-width: @screen-tablet-min) {
    background-image: url('@{filename}_768.jpg');
  }
  @media(max-width: @screen-xs) {
    background-image: url('@{filename}_480.jpg');
    height: 186px;
    top: 100px;
  }
  @media(max-width: 320px) {
    background-image: url('@{filename}_320.jpg');
    height: 186px;
    top: 100px;
  }
}.clearfix {
  display: block;
  zoom: 1;

  &:after {
    content: '';
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
/**** Animations ****/
.animated-fast {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-4-13 15:7:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) rotate(720deg);
    transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) rotate(120deg);
    transform: translateX(1000px) rotate(120deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-in-2-bck-ccw {
  0% {
    -webkit-transform: translateZ(200px) rotate(45deg);
    transform: translateZ(200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-bck-ccw {
  0% {
    -webkit-transform: translateZ(200px) rotate(45deg);
    transform: translateZ(200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

.roll-in-blurred-right {
  -webkit-animation: roll-in-blurred-right .65s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1.5s both;
  animation: roll-in-blurred-right .65s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1.5s both;
}
.rotate-in-2-bck-ccw {
  -webkit-animation: rotate-in-2-bck-ccw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: rotate-in-2-bck-ccw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}