/*** C21 is not built with a grid framework; add utilities and grid components here.  *****/
@import "newVariables";
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
.img-responsive {
  max-width: 100%;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}
//*** Grids ***/
@cols: 12;
@sm: ~"only screen and (max-width: 480px)";
@md: ~"only screen and (max-width: 768px)";
@lg: ~"only screen and (max-width: 1024px)";

.container {
  max-width: 1140px;
  //display: flex;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  //width: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
 [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.generate-grid (@cols);
.generate-grid (@index) when (@index > 0) {
  .generate-grid (@index - 1);
  .col-@{index} {
    width: @index / @cols * 100%;
    flex-basis:  @index / @cols * 100%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  @media (min-width: @screen-sm) {
    .col-sm-@{index} {
      max-width: @index / @cols * 100%;
      flex-basis:  @index / @cols * 100%;

    }
  }

  @media (min-width: @screen-md) {
    .col-md-@{index} {
      max-width: @index / @cols * 100% !important;
      flex-basis:  @index / @cols * 100% !important;
    }
  }
  @media (min-width: @screen-lg) {
    .col-lg-@{index} {
      max-width: @index / @cols * 100% !important;
      flex-basis:  @index / @cols * 100% !important;
    }
  }
}

/*** Flexbox Utilities ***/
.d-flex {
  display: flex;
}
.d-sm-flex {
  @media (min-width: @screen-sm) {
    display: flex;
  }
}
.d-md-flex {
  @media (min-width: @screen-md) {
    display: flex;
  }
}

/**** Text Utilities ***/
.text-center {
  text-align: center;
}
.text-center-xs {
  @media (max-width: @screen-phone-max) {
    text-align: center;
  }
}
.text-uppercase {
  text-transform: uppercase;
}

/*** Visibility Utilities ***/
.hidden-sm-down {
  @media (max-width: @screen-phone-max) {
    display: none;
  }
}
.hidden-md-down {
  @media (max-width: @screen-desktop-min) {
    display: none;
  }
}
.hidden-md-up {
  @media (min-width: @screen-tablet-min) {
    display: none;
  }
}