@import "_mixins";

@header-height:97px;
@color-c21-gold: #beaf87; //#relentless-gold
@color-c21gold: @color-c21-gold; // compatibility
@color-c21darkgold: #A19276;
@color-c21-gold-ada: #847252;
@color-c21-gold-ada-dark: #78674A;
@color-c21-gold-medium-dark: #7E6C4A;
@color-c21grey: #38383a;
@obsessed-grey: #252526;
@color-c21light: #e6e7e8;
@color-ada-gold: #847252;
@color-c21-darkest-gold: #5F5544;

//colors
@gold: @color-c21-gold;
@black: #000;
@white: #fff;
@grey:   #D3D3D3;
@dark-grey: #575757;
@light-grey: #c7c7c7;
@text-grey: @dark-grey;
@button-blue: transparent;
@blue:  @color-c21-gold;
@slate: #273035;
@off-white: #fffbf3;
@off-white-cool: #f8f7f2;
@purple: @color-c21-gold;
@orange: @color-c21-gold;
@dark-text: #53534D;
@filter-grey: #535353;
@search-gray: #f9f9f9;
@very-dark-grey: #424242;
@dark-blue: @color-c21-gold;
@gold-on-grey: #746649;


@redesign-gold: @color-c21-gold;
@redesign-black: @color-c21grey;
@redesign-grey: #808285;
@redesign-light-grey: @color-c21light;
@redesign-white: #e6e7e8;


@header-selected: @white;
@header-color: #b5b5b5;
@header-background-color: @slate;
@header-link-color: #0B5095;

@screen-phone-max: 767px;
@screen-tablet-min: (@screen-phone-max + 1px);
@screen-tablet: @screen-tablet-min;
@screen-tablet-max: 1023px;
@screen-desktop-min: (@screen-tablet-max + 1px);
@screen-desktop: @screen-desktop-min;
@screen-desktop-large: 1600px;

@screen-xxs: 320px;
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-xs-max: 767px;
@screen-sm: 768px;
@screen-xs-max: (@screen-sm - 1px);
@screen-sm-min: @screen-sm;
@screen-md: 992px;
@screen-sm-max: (@screen-md - 1px);
@screen-md-min: @screen-md;
@screen-lg: 1200px;
@screen-md-max: (@screen-lg - 1px);
@screen-xl: 1366px;
@screen-xxl: 1900px;

/***** Typography *****/

@headline-font: "BarlowSemiCondensed", Helvetica, Arial, sans-serif;
@subhead-condensed-font: "TypoldCondensed","Typold","Helvetica","Arial",sans-serif;
@subhead-font: "Typold", Helvetica, Arial, sans-serif;
@subhead-extended-font: "TypoldExtended","Typold", Helvetica, Arial, sans-serif;
@body-font: "Oakes", Helvetica, Arial, sans-serif;
