@import "newVariables";
@import "_mixins";
@import "_fonts";
@import "_utilities";
@import "_navigation";

@footer-yellow-color: @color-c21-gold; // #eeb22b;
@footer-grey-color: @color-c21light;
@footer-grey-hover: #d5d3d3;
@footer-show-more-color: darken(@color-ada-gold, 10%);
@footer-show-more-hover: @color-ada-gold;
@footer-disclaimer-color: #a7a7a7;

//New Colors For Redesign 2017-02-23
@redesign-gold: @color-c21-gold;
@redesign-black: #38383a;
@redesign-grey: #808285;
@redesign-white: #e6e7e8;



//
// Utilities
// --------------------------------------------------
.clearfix {
   clear: both;
}
.pull-right {
   float: right !important;
}
.pull-left {
   float: left !important;
}
.hidden {
   display: none !important;
   visibility: hidden !important;
}

.visible-xs, .visible-xs-inline {
   display: none;
}

@media (max-width: @screen-phone-max){
   .hide-small{
      display: none !important;
   }
   .visible-xs {
      display: block;
   }
   .visible-xs-inline {
      display: inline;
   }
   .hidden-xs {
      display: none !important;
   }
}
@media(max-width: @screen-phone-max) {
   .hide-tablet {
      display: none;
   }
}
.hidden-sm {
   @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      display: none;
   }
}
.visible-sm-inline-block {
   display: none;
   @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      display: inline-block;
   }
}

//IE Nonsense
::-ms-clear { display: none; }

// ----------- Begin Keyframe Styling -------------
@-webkit-keyframes fadeInDown {
   from {
      opacity: 0;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
   }

   to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
   }
}

@keyframes fadeInDown {
   from {
      opacity: 0;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
   }

   to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
   }
}


.fadeInDown {
   -webkit-animation-name: fadeInDown;
   animation-name: fadeInDown;
}



.force-small-caps {
   font-size: 1em;
}



@media(min-width: @screen-tablet-min) {
   #MobileTest {
      display: none;
   }
}

// ----------- End Keyframe Styling -------------

// ----------- Begin Header Styling -------------
body {
   // Header bar needs a low z-index on homepage, but a high
   // z-index on the SRP
   &#srp .header-main-bar {
      z-index: 5;
   }
   &:not(.home) {
      .deco-logo {
         display: none;
      }
   }
}

//Things not to include in a print
@media print {
   .site-header {
      display: none;
   }
   #BodyRightLane {
      display: none;
      .LaneTitle {
         display: none;
      }
      #similarProperties {
         display: none;
      }
      #mortgageCalculator {
         display: none;
      }
   }
   .LaneText.footnoteTxt {
      display: none;
   }
}

// ----------- Login Window Styling -------------
#ModalWindow {
   #modalContainer {
      #modalTitle {
         h1 {
            letter-spacing: normal;
         }
      }
      #modalCloseButton {
         .pngIconFallback(40px, 0, '/images/buttons/button-close');
      }
      .btn-primary:hover {
         color: @obsessed-grey !important;
      }
      #signinWindow {
         #modalShadowWrapper {
            #modalLoginLeftLane {
               .modalLoginInput {
                  height: 25px;
               }
               #signinActions {
                  top: 60px;
                  right: 19px;
               }
               #zapLoginText {
                  margin: 10px 20px 0px 15px;
               }
            }
            #modalLoginRightLane {
               height: 80px;
            }
         }
      }
   }
   .pageFormError {
      width: auto !important;
   }
}

// ----------- End Header Styling -------------

// ----------- Begin PageBody Styling -------------
.ccpa-contact-form-disclaimer {
   color: #555 !important;
   font-size: 11px;
   float: left;
   font-family: BarlowSemiCondensed, Helvetica, Arial, sans-serif;
   &.agent-form, &.office-form {
      @media (min-width: @screen-tablet-min) and (max-width: @screen-tablet-max) {
         padding-top: .1em;
         p:nth-child(2) {
            margin-bottom: 0;
         }
      }
   }
   @media(min-width: @screen-tablet-min) {
      float: none;
      clear: both;
   }
   p {
      margin-bottom: 0.4em;
      line-height: 1.4em;

      span {
         text-transform: uppercase;
      }
   }
}
.PageTopGlass {
   display: none;
}
#PageBody {
   background: #fff;
   &.Standard2LaneRight {
      #BodyLeftLane {
         padding: 0;
      }
      #BodyRightLane {
         padding: 0;
         width: 204px;
         .LaneTitle {
            background: @color-c21grey; // #1f1f1f;
            font-family: @subhead-font;
         }
         .rightLaneBgContainer {
            background: @color-c21light; // #dbd3c9;
         }
         .RightLanePaginator {
            padding-left: 0;
         }
         a.PaginatorUp {
            .pngIconFallback(95px, 0, '/images/portal/chevron-scroll-top');
            position: inherit;
            width: 100%;
            height: 25px;
            border-right: 0;
            border-bottom: 1px solid @color-c21-gold; // #BCAE9C;
            &:hover {
               background-color: #ffffff;
            }
         }
         a.PaginatorDown {
            .pngIconFallback(95px, 0, '/images/portal/chevron-scroll-down');
            position: inherit;
            width: 100%;
            height: 25px;
            &:hover {
               background-color: #ffffff;
            }
         }
      }
   }

}

#XtDirectFloatingButton {
   display: none;
}

html {
   #modalContainer {
      #modalShadowWrapper, #modalContent {
         border: none;
      }
      #modalTitle {
         background: @color-c21grey; // #2d2d2d;
         color: @color-c21-gold;
      }
      #modalGlass, #modalTop, #modalBottom, #modalMiddle {
         display: none;
      }
      &.myc21LoginBox {
         width: 360px;
      }
   }
}

// ----------- Begin Footer Styling -------------
#footer-additional-disclaimer {
   display: none;
}


body.home #site-footer {
   z-index: 5;
}

#site-footer {
   background: @obsessed-grey;
   color: @color-c21light;
   position: relative;
   z-index: 5;
   padding-top: 125px;
   /*.go-to-top {
      width: 50px;
      margin: 0 auto;
      padding: 2px 15px;
      text-align: center;
      border-radius: 0 0 5px 5px;
      cursor: pointer;
      padding-right: 20px;
      .pngIconFallback(10px, 18px, '/images/portal/chevron-scroll-top');
      background-color: #111111;
      span {
         color: @footer-grey-color;
         margin-left: 20px;
      }
   }*/
   .deco-logo {
      width: 130px;
      position: absolute;
      left: 50%;
      margin-left: -67px;
      top: -65px;
      svg {
         fill: @color-c21-gold;
      }
   }
   .footer-sidebar {
      background: @color-ada-gold;
      color: @white;
      padding: 2.6rem;
      max-width: 300px;
      margin: auto;
      @media (max-width: @screen-phone-max) {
         margin-bottom: 2em;
      }
      @media (min-width: @screen-tablet-min) and (max-width: @screen-tablet-max){
         padding: 2.6rem 2rem;
      }

      a {
         color: @white;
      }
      .footer-phone {
         font-family: @headline-font;
         font-size: 14px;
         letter-spacing: 1.68px;
         a {
            &:hover, &:focus {
               text-decoration: underline;
            }
         }
      }

      .footer-links {
         a {
            display: block;
            font-weight: bold;
            padding: 8px 0;
            &:hover, &:focus {
               text-decoration: underline;
            }
         }
         &.ccpa {
            margin-top: 2em;
            a {
               font-size: 14px;
               font-family: Arial, Helvetica, sans-serif;
            }

         }
      }
   }

   .logotype {
      margin: 2em auto;
      a {
         .pngIconFallback(0, 0, '/images/portal/Century21-logo');
         display: block;
         height: 60px;
         width: 156px;
         text-indent: -9999px;
         margin: 0;
         @media (max-width: @screen-xs-max) {
            margin: auto;
         }
         @media (min-width: @screen-tablet-min) and (max-width: @screen-tablet-max){
            // width: 138px;
         }
      }
   }

   .footer-sections {
      //margin: auto;
      @media (max-width: @screen-phone-max) {
         max-width: 300px;
         margin: auto;
         .footer-section {
            text-align: left;
         }
      }
      &.shared {
         width: 100%;
         @media (min-width: @screen-md) {
            width: 35%;
         }
         nav {
            margin-bottom: 2em;
            a {
               position: relative;
               padding-left: 12px;
               &:before {
                  content: "";
                  position: absolute;
                  top: 10px;
                  left: 0;
                  height: 1px;
                  width: 1px;
                  background: @color-c21gold;
               }
            }
         }
      }
      &.popular {
         width: 100%;
         @media (min-width: @screen-md) {
            width: 65%;
         }
         nav:first-of-type {
            @media (min-width: @screen-md) {
               margin-bottom: 4rem;
            }
         }
         a.navTitle {
            @media (min-width: @screen-md) {
               display: inline-block;
               width: 48%;
            }
            @media (min-width: @screen-lg) {

               width: 172px;
            }
         }
      }
      nav {
         //padding: 15px;
         line-height: 24px;
         //width: 24%;
         -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
         box-sizing: border-box;
         text-align: left;
         vertical-align: top;
         display: block;
         @media(max-width: @screen-phone-max)  {
            width: 100%;
            text-align: center;
         }
         div {
            font-family: @subhead-font;
            color: @redesign-gold;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
            letter-spacing: 1.92px;
         }
         a.navTitle {
            display: block;
            color: @footer-grey-color;
         }
         a:hover {
            color: @footer-grey-hover;
            text-decoration: underline;
         }
         a:nth-child(n+15) {
            display: none !important;
            height: 0;
            //width: 0 !important;
            min-height: auto;
            overflow: hidden;
            transition: .6s all ease;
         }
         .showMore-toggle {
            display: none;
            position: absolute;
            left: -100%;
            top: -100%;
            ~ label {
               -webkit-font-smoothing: antialiased;
               cursor: pointer;
               color:  @color-c21-gold;
               vertical-align: top;
            }
            ~ label:hover {
               color: #ffffff;
               //color: lighten(@color-c21-gold, 10%);
            }
            ~ label:after {
               content: "Show More";
            }
         }
         .showMore-toggle:checked ~ label:after{
            content: "Show Less";
         }
         .showMore-toggle:checked ~ a{
            display: block !important;
            //min-height: 20px;
            height: auto;
            @media (min-width: @screen-md) {
               display: inline-block !important;
            }
         }
      }
   }
   .footer-disclaimer {
      margin-bottom: -2em;
      @media (min-width: @screen-sm) {
         padding-top: 3em;
         padding-bottom: 3em;
      }
      .region {
         @media (max-width: @screen-xs-max) {
            margin-top: 2em;
         }
      }
      a {
         &.ccpa-personal-info-disclaimer {
            display: block;
            font-size: 15px;
            font-weight: bold;
            color: #ffffff !important;
            text-decoration: underline;
            &:hover,
            &:focus {
               text-decoration: none;
            }
            @media (max-width: @screen-phone-max) {
               text-align: center;
            }
         }
      }
      .footer-row {
         align-items: flex-start;
         .logo-seal {
            .pngIconFallback(15px, 10px, '/images/portal/logo-seal-2');
            background-position: left center;
            background-size: contain;
            height: 64px;
            width: auto;
         }
         .footer-disclaimer-wrap {
            margin-top: 15px;
         }
         .copyright-text {
            //display: inline-block;
            color: @footer-disclaimer-color;
            @media (max-width: @screen-phone-max) {
               margin-left: 0;
               text-align: center;
            }
         }
         .franchise-disclaimer {
            font-size: 10px;
            color: @footer-disclaimer-color;
            margin-top: 5px;
         }
         #footer-additional-disclaimer {
            color: @footer-disclaimer-color;
            .additional-disclaimer {
               display: block;
               border-top: 1px solid @footer-grey-color;
               padding-top: 26px;
            }
            .onboard-disclaimer {
               display: block;
               img {
                  display: block;
                  margin: 20px auto;
                  width: 0;
                  height: 0;
                  padding: 16px 77px;
                  .pngIconFallback(0, 0, '/images/portal/obi-logo');
               }

            }
         }
         #cms-page-disclaimer {
            display: none;
            color: @footer-disclaimer-color;
            a {
               color :@color-c21-gold;
            }
         }
         a {
            color :@color-c21-gold;
         }
         a:hover {
            color: lighten(@color-c21-gold, 10%);
         }

      }
      .btn-outline-primary {
         color: @color-c21-gold;
         border-color: @color-c21-gold;
         &:hover, &:focus {
            color: @white !important;
            border-color: #847252;
         }
      }
   }

   .language-chooser {
      display: inline-flex;
      align-items: center;
      position: relative;
      letter-spacing: 1px;
      @media (min-width: @screen-md) and (max-width: @screen-md-max) {
         letter-spacing: .4px;
      }
      @media (min-width: @screen-lg) {
         letter-spacing: 1.7px;
      }
      .region-icon {
         width: 13px;
         height: 14px;
         margin-right: 6px;
         padding-left: 0;
         svg {
            path {
               fill: currentColor;
            }
         }
      }
      .icon {
         width: 8px;
         height: 7px;
         margin-left: 6px;
      }
      span {
         padding-right: 4px;
      }

      &.c21-dropdown-open {
         .language-options {
            display: flex;
         }
      }
   }
   .region-btn {
      img {
         width: 19px;
         height: 13px;
         display: inline-block;
      }
   }
   .footer-bottom {
      font-family: @subhead-font;
      border-top: 3px solid @redesign-gold;
      @media (min-width: @screen-tablet-min) {
         .footer-row {
            .footer-container {
               display: inline-block;
            }
            .footer-container:first-child {
               width: 25%;
            }
            .footer-container:nth-child(2) {
               width: 52%;
               .footer-links {
                  @media(min-width: @screen-sm) {
                     text-align: left;
                  }
                  a {
                     display: inline-block;
                     width: auto;
                     padding: 0 10px;
                     @media (max-width: @screen-tablet-max) {
                        padding: 0 5px;
                     }
                     .tabletFontSize();
                  }
               }
            }
            .footer-container:nth-child(3) {
               width: 22%;
               vertical-align: middle;
            }
         }
      }
      @media (min-width: @screen-desktop-min) {
         .footer-container:first-child {
            width: 20%;
         }
         .footer-container:nth-child(2) {
            width: 55%;
         }
      }
      .footer-row {
         background-color: @redesign-gold;
         @media(max-width: @screen-phone-max) {
            padding: 10px;
         }
         .footer-container {
            text-align: center;
            margin: 10px 0;
            .footer-phone {
               margin-left: -5px;
               padding-left: 20px;
               height: 20px;
               display: inline-block;
               .pngIconFallback(0,0,'/images/portal/phone');
               a {
                  color: @redesign-black;
                  font-size: 14px;
               }
            }
            /*.footer-links {
               a {
                  font-size: 14px;
                  color: @redesign-black;
                  display: block;
                  width: 100%;
                  padding: 5px 0;
               }
            }*/

         }
      }
   }
}
.social-icons {
   padding: 1em 0;
   .social-icon {
      display: inline-block;
      height: 19px;
      width: 19px;
      margin-right: 16px;
      text-indent: -9999px;
      background-size: contain !important;

      a {
         display: block;
         height: inherit;
         width: inherit;
         text-indent: -9999px;
      }
      @media (max-width: @screen-phone-max) {
         padding-right: 14px;
      }

   }
   .social-icon-facebook {
      .pngIconFallback(0,0,'/images/portal/ionicons-facebook');
   }
   .social-icon-google {
      .pngIconFallback(0,0,'/images/portal/ionicons-google');
   }
   .social-icon-instagram {
      .pngIconFallback(0,0,'/images/portal/ionicons-instagram');
      padding-right: 0;
   }
   .social-icon-twitter {
      .pngIconFallback(0,0,'/images/portal/x-logo-black');
   }
   .social-icon-youtube {
      .pngIconFallback(0,0,'/images/portal/ionicons-youtube');
   }
}

#language-options-modal.language-options {
   font-size: 11px;
   font-family: @subhead-font;
   line-height: 25px;
   color: @redesign-black;
   width: 100%;
   background-color: @obsessed-grey;
   background-image: url(/images/portal/LanguageOverlay-375.png);
   background-size: cover;
   padding: 20px;
   z-index: 10000;
   margin: 0;
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   height: 100vh;
   letter-spacing: .05em;
   display: none;
   &.open {
      display: flex;
   }
   @media (max-width: @screen-xs-max) {
      overflow: auto;
   }
   .animated-fast();
   @media(max-width: @screen-phone-max) {
      width: inherit;
   }
   @media(min-width: @screen-md) {
      background-image: url(/images/portal/LanguageOverlay-1440.png);
   }
   //Close Button
   .close {
      position: absolute;
   }
   .icon {
      width: 8px;
      height: 7px;
      margin-left: 6px;
   }
   .h1 {
      color: @white;
      font-size: 24px;
      letter-spacing: 2.28px;
      font-weight: normal;
      padding-bottom: .5em;
      text-align: center;
   }
   .language-inner {
      background: @color-c21gold;
      text-align: left;
      border-left: 54px solid @color-c21darkgold;
      padding: 8px 0;
      @media (min-width: @screen-tablet-min) {
         border-left: 85px solid @color-c21darkgold;
      }
      .language-usa, .language-global {
         .icon-region {
            fill: @white;
         }
      }
      .language-usa {
         @media (max-width: @screen-xs-max) {
            .language-esp, .language-active {
               width: 90px;
            }
         }
      }
   }
   .language-wrap {
      width: 470px;
      margin: auto;
      @media (min-width: @screen-tablet-min) {
         width: 510px;
      }
   }
   .language-title {
      line-height: 32px;
      text-align: left;
      border-bottom: 1px solid #596064;
      text-transform: none;
      font-weight: normal;
      strong {
         color: @redesign-black;
         font-size: 16px;
      }
   }
   a {
      color: @redesign-black !important;
      display: inline-block;
      width: 128px;
      text-transform: none;
      font-weight: normal;
      &:hover {
         color: @white;
      }
   }
   .icon-region {
      width: 40px;
      height: 40px;
      position: absolute;
      margin-left: -59px;
      @media (min-width: @screen-sm) {
         width: 60px;
         height: 60px;
         margin-left: -82px;
      }
   }
   .language-active {
      color: @white;
      padding-left: 11px;
      width: 117px;
   }
   .language-usa {
      padding: 0 12px 35px 84px;
      margin-left: -72px;
   }
   .language-global {
      padding: 0 12px 6px 84px;
      margin-left: -72px;
   }
}

body .site-header #dropdown-homepage-container,
body.error .search-section .homepage-main-search,
body.home .homepage-bottom .homepage-main-search {
   #dropdownResults {
      right: 0;
      width: auto;
      top: auto;
      font-size: 12px;

      .FTSpropDetailBg, .FTSagentDetailBg, .FTSofficeDetailBg {
         background: #f8f8f6;
      }

      @media(max-width: @screen-phone-max) {
         right: auto;
      }

      /* Element for old bar IE background support */
      #dropdownRepeat {
         display: none;
         background: none;
      }

      /* Element for old bar IE background support */
      #dropdownResultsTop {
         display: none;
         background: none;
      }

      /* Element for old bar IE background support */
      #dropdownResultsBottom {
         display: none;
         background: none;
      }

      /* Search filters, which no longer appear */
      #dropdownSearchContent {
         display: none;
         background: none;
      }

      #dropdownContent {
         padding: 0;
         background: #fff;
         border: none;
         box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      }

      .noResults {
         display: none;
      }

      .lastExpanding {
         border-bottom: 1px solid #fff;
      }
      .showLessLinkDiv {
         a {
            background: none;
            color: #b3b3b3;
            font-size: 15px;
            &:before {
               content: '-'
            }
         }
      }
      .moreLinkDiv {
         a {
            background: none;
            color: #b3b3b3;
            font-size: 15px;
            &:before {
               content: '+';
            }
         }
      }

      @media(min-width: @screen-tablet-min) {
         top: 35px;

         @media(max-width: 865px) {
            right: -85px;
         }

         #dropdownContent {
            line-height: normal;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0px 0px 5px rgba(0,0,0,0.5);

            #searchBody {
               #suggestMessageDropDown {
                  background: #FFF7DC;
                  min-height: inherit;
                  width: auto;
                  #dropDownNotification {
                     padding: 10px !important;
                     height: auto !important;
                  }
               }
            }

            #dropdownContentTop {
               display: none;
            }

            #dropdownContentRepeat {
               display: none;
            }

            #innerSearch {
               .parentHover {
                  background: #f8f8f6;
               }
            }
            .infoCount {
               color: @color-ada-gold;
            }
            #locationResultsDiv {
               .propMLS {
                  b {
                     color: #0B5095;
                     font-weight: normal;
                  }
               }
               .ftsLocation {
                  width: 120px;
                  display: inline-block;
                  position: relative;
                  top: -2px;
               }
               .locationRow {
                  font-size: 12px;
               }
               .locationCellWrapper {
                  display: inline-block;
                  line-height: 15px;
                  .cell {
                     width: 112px;
                     &:nth-child(4), &:nth-child(5) {
                        display: none;
                     }
                  }
               }
               .ftsLocationListings span {
                  .pngIconFallback(center, center, '/images/freetextsearch/new/fts-location');
               }
               .ftsLocationAgents span {
                  .pngIconFallback(center, center, '/images/freetextsearch/new/fts-agent');
               }
               .ftsLocationOffices span {
                  .pngIconFallback(center, center, '/images/freetextsearch/new/fts-office');
               }
            }
            .moreLinkDiv {
               a {
                  margin-top: -18px;
               }
            }
            #locationdetailsDiv {
               background: #fff;
               cursor: default;

               .topSuggest {
                  border: 0;
                  height: 95px;
                  padding-top: 4px;

                  .forSaleSuggest,
                  .forRentSuggest,
                  .c21AgentsSuggest,
                  .c21OfficesSuggest {
                     background: #fff;
                     border: 0;
                     width: 158px;
                     height: 90px;
                     position: relative;
                  }
                  .infoLabel {
                     color: #575757;
                     font-weight: normal;
                  }
                  /*.infoCount {
                     color: @color-c21-gold; // #eeb22b;
                  }*/
                  .newViewLocalArea {
                     color: #575757;
                  }
                  .c21OfficesImage,
                  .c21AgentsImage,
                  .forRentImage,
                  .forSaleImage {
                     top: 11px;
                     left: 0px;
                     height: 52px;
                     width: 100%;
                  }

                  .forSaleInfo,
                  .forRentInfo,
                  .c21AgentsInfo,
                  .c21OfficesInfo {
                     position: absolute;
                     top: auto;
                     bottom: 0px;
                     width: 100%;
                  }

                  .c21OfficesInfo {
                     /* .infoCount {
                         color: @color-c21-gold; // #e94d2d;
                      }*/
                  }

                  .c21AgentsInfo {
                     /*.infoCount {
                        color: @color-c21-gold; // #80b877;
                     }*/
                  }

                  .c21OfficesImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/offices-2');
                  }

                  .c21AgentsImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/agents-2');
                  }

                  .forRentImage, .forSaleImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/house-2');
                  }
                  .disabled {
                     .infoCount {
                        color: @text-grey;
                     }
                     .forRentImage, .forSaleImage {
                        .pngIconFallback(center, center, '/images/freetextsearch/new/house-2-disabled');
                     }
                     .c21OfficesImage {
                        .pngIconFallback(center, center, '/images/freetextsearch/new/offices-2-disabled');
                     }
                     .c21AgentsImage {
                        .pngIconFallback(center, center, '/images/freetextsearch/new/agents-2-disabled');
                     }
                  }
               }

               .centerSuggest {
                  background: #f7f7f7;
                  border: 1px solid #ccc;
                  border-radius: 3px;
                  margin: 10px 0px 14px 10px;
                  width: 608px;
                  padding: 4px 0px;
                  height: 65px;

                  .disabled {
                     .infoCount > span {
                        color: @text-grey;
                     }
                  }
                  .openHousesSuggest,
                  .newlyListedSuggest,
                  .priceReducedSuggest,
                  .foreclosuresSuggest {
                     background: none;
                     width: 148px;
                     position: relative;
                     &.disabled, &.disabled a {
                        cursor: default
                     }
                  }

                  .foreclosuresInfo,
                  .priceReducedInfo,
                  .newlyListedInfo,
                  .openHousesInfo {
                     position: static;
                     height: 100%;
                  }

                  .priceReducedImage,
                  .newlyListedImage,
                  .openHousesImage,
                  .foreclosuresImage {
                     top: 4px;
                     left: 36px;
                     width: 36px;
                     height: 30px;
                     background-position: center;
                     background-repeat: no-repeat;
                  }
                  .openHousesImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/icon_open-house');
                  }
                  .newlyListedImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/icon_newlylisted');
                  }
                  .priceReducedImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/icon_price-reduced');
                  }
                  .foreclosuresImage {
                     .pngIconFallback(center, center, '/images/freetextsearch/new/icon_foreclosures');
                  }
                  a {
                     display: block;
                     height: 100%;
                     position: relative;

                     .infoLabel {
                        position: absolute;
                        bottom: 3px;
                        width: 100%;
                        text-transform: uppercase;
                        font-weight: 100;
                        color: #575757
                     }

                     .infoCount {
                        border: 1px solid #ccc;
                        background: #fff;
                        border-radius: 16px;
                        padding: 2px 4px;
                        text-transform: uppercase;
                        top: 7px;
                        position: absolute;
                        right: 31px;
                        font-weight: bold;
                        color: transparent;

                        & > span {
                           color: @color-ada-gold; // #e94d2d;
                        }
                     }
                  }
               }

               .bottomSuggest {
                  background: #fff;
                  padding: 0 12px;
                  width: 100%;
                  box-sizing: border-box;

                  .viewLocalArea {
                     display: none;
                  }

                  .newViewLocalArea {
                     // Normally don't do this, but it was requested
                     display: block !important;

                     .left {
                     }

                     .right {
                        color: @color-ada-gold; // #e94d2d;
                        border: 1px solid @color-ada-gold; // #e94d2d;
                        border-radius: 5px;
                        padding: 5px 10px;
                        display: inline-block;
                        float: right;
                        cursor: pointer;
                        &:hover {
                           background: @color-ada-gold; // #e94d2d;
                           color: @white;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

body.home .homepage-bottom .homepage-main-search #dropdownResults {
   right: auto;
   left: -52px;
   top: 60px;
}

body.map-view, body.gallery-view {
   a {
      text-decoration: none;
   }
   @media(max-width: @screen-phone-max) {
      #results-container {
         width: 100%;
         .no-results-section {
            bottom: 40px;
            left: 26px;
            width: 80%;
            position: absolute;
            z-index: 999999;
            background: rgba(0,0,0,0.5);
            color: white;
         }
      }
   }
}

body.map-view {
   #site-footer {
      padding-top: 1em;
      [class*=col-] {
         max-width: 100% !important;
         flex-basis: 100% !important;
      }
      .footer-sidebar {
         max-width: 260px;
         margin: 1em auto;
      }
      .logo-seal {
         display: none;
      }
      .footer-sections {
         margin: auto;
         text-align: left;
         width: 50%;
         nav {
            width: 100%;
            text-align: left;
            /* div {
                font-size: 12px;
                line-height: 1.5;
             }*/
         }
      }
      .footer-disclaimer {
         .footer-row {
            .footer-container {
               .footer-disclaimer-wrap{
                  width: 100%;
                  padding: 0;
               }
               .logo {
                  display: block;
                  position: relative;
                  float: left;
                  margin: auto;
                  width: 100%;
                  /*padding-bottom: 0;
                  width: 75px;
                  bottom: 131px;*/
                  a{
                     background-position: 46% 0px;
                  }
               }
            }
         }
      }
      .footer-bottom {
         .footer-row {
            .footer-container {
               display: inline-block;
               width: 100% !important;
            }
            .footer-container:first-child {
               width: 20%;
               border-right: none !important;
            }
            .footer-container:nth-child(2) {
               width: 55%;
               .footer-links {
                  a {
                     display: inline-block;
                     width: auto;
                     padding: 10px 10px;
                     .tabletFontSize();
                     width: 100%;
                  }
               }
            }
            .footer-container:nth-child(3) {
               width: 22%;
               vertical-align: middle;
            }
         }

      }
      /*@media not all and (min-resolution:.001dpcm) {
         @supports (-webkit-appearance:none) {
            .language-options {
               @media (min-width: @screen-sm) {
                  left: auto;
                  width: 384px;
                  overflow-y: scroll;
                  padding-top: 150px;
               }
               @media (min-width: 1280px) {
                  width: 514px;
               }
            }
         }
      }*/

   }
}
body.gallery-view {
   .onboard-disclaimer {
      img {
         margin: 20px 0 !important;
      }
   }

   .footerSeoContainer {
      display: block;
      .footerSeo {
         .rightSection.description {
            width: auto;
            margin-top: 20px;
         }
         @media (min-width: @screen-desktop-large) {
            .topSectionNearbyCity, .topSectionZip, .topSectionCity.propTypes, .rightSection.description {
               width: 24%;
            }
            .rightSection.description {
               margin-top: 0px;
            }
         }
         @media (max-width: @screen-desktop-min) {
            .topSectionNearbyCity, .topSectionZip, .topSectionCity.propTypes {
               width: 30%;
            }
            .rightSection.description {
               width: auto;
            }
         }
         @media (max-width: @screen-phone-max) {
            .topSectionNearbyCity, .topSectionZip, .topSectionNearbyCounty, .topSectionCity.propTypes {
               display: block;
               width: auto;
               margin: 18px 0%;
               text-align: center;
            }
         }
      }
   }
}
/* ------------ Begin Legacy Pages Work ------------ */

#PageBody.Standard2LaneRight {
   display: table;
   padding-bottom: 0px !important;
   background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAAABCAIAAAAdNtbPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFFmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1sbnM6cGhvdG9zaG9wPSJodHRwOi8vbnMuYWRvYmUuY29tL3Bob3Rvc2hvcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RFdnQ9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZUV2ZW50IyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE4LTAyLTI3VDIwOjQ5OjA3LTA3OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxOC0wMi0yN1QyMDo0OTo1MC0wNzowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxOC0wMi0yN1QyMDo0OTo1MC0wNzowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9maWxlPSJzUkdCIElFQzYxOTY2LTIuMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyZTE2Nzc3OC1hNTJiLTQzNDYtYThiNC0xMTM4OGMzNmE5OGUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MmUxNjc3NzgtYTUyYi00MzQ2LWE4YjQtMTEzODhjMzZhOThlIiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6MmUxNjc3NzgtYTUyYi00MzQ2LWE4YjQtMTEzODhjMzZhOThlIj4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDoyZTE2Nzc3OC1hNTJiLTQzNDYtYThiNC0xMTM4OGMzNmE5OGUiIHN0RXZ0OndoZW49IjIwMTgtMDItMjdUMjA6NDk6MDctMDc6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCBDQyAoTWFjaW50b3NoKSIvPiA8L3JkZjpTZXE+IDwveG1wTU06SGlzdG9yeT4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7axbQMAAAAFUlEQVQoFWM8emCXoroewygYBTQDAHm/ArecDxRtAAAAAElFTkSuQmCC');
   background-position: right, 0;
   background-repeat: repeat-y;
   background-color: #fff;
   @media(max-width: @screen-tablet-max) {
      background: none;
   }
   #BodyLeftLane {
      display: inline-block;
      @media(max-width: @screen-tablet-max) {
         width: 768px;
      }
      .LaneTitle {
         background-color: @color-c21grey; // #333333;
         font-family: @subhead-font;
         a {
            color: @color-c21-gold; // #fec321;
         }
      }
      #agentLeftLane {
         background: none;
      }
      #agentRightLane {
         @media (min-width: @screen-sm){
            width: 570px;
            #leftBlock {
               padding-right: 0;
            }
            #areaServed {
               padding-left: 1em;
            }
         }
         .directionsTeaser h2 {
            font-size: 16px;
         }
      }
      #mobileContactCard {
         @media(max-width: @screen-tablet-max) {
            display: inherit !important;
            width: 100%;
         }
      }
      #PropDetailBg {
         @media(max-width: @screen-tablet-max) {
            width: 768px;
         }
      }
      #officeLeftLane {
         background-color: #f0f1f2;
         float: none;
         display: table-cell;
         vertical-align: top;
         padding-bottom: 250px;
         max-height: 900px;
         width: 180px;
         box-sizing: border-box;
         .officeInfoList {
            color: @very-dark-grey;
            h4 {
               color: @very-dark-grey;
            }
         }
      }
      #officeRightLane {
         padding-top: 33px;
         padding-left: 10px;
         padding-right: 0;
         display: table-cell;
         float: none;
         #contactInfoBlock {
            display: table-cell;
            float: none;
            a {
               &:hover, &:focus {
                  text-decoration: underline;
               }
            }
            .phoneNumber{
               // color: @redesign-gold;
               color: @color-c21grey;
               &:hover, &:focus {
                  color: @color-c21-gold-ada-dark
               }
            }
         }
         #areaServed {
            float: none;
            display: table-cell;
            #officeActions {
               margin-left: -10px;
            }
            .officeDetailPhotoContainer {
               margin: 0 auto;
               border: none;
               width: 280px;
               display: block;
               img {
                  width: 263px;
               }
            }
            #viewAllOfficeAgents {
               background: @redesign-gold;
               color: @obsessed-grey;
               margin: 0.5em .5em 1.5em 0;
               padding: 5px 10px;
               width: 250px;
               letter-spacing: normal;
               font-weight: normal;
               text-transform: none;
               font-size: 10px;
               &:hover, &:focus {
                  background: @color-c21darkgold; // #f2c356;
               }
               span {
                  background: none;
               }
               @media (max-width: @screen-tablet-max) {
                  width: 245px;
                  height: auto;
                  padding: 5px 8px;
               }
            }
         }
         #directionsTeaser {
            display: table-cell;
            padding-top: 30px;
            padding-bottom: 15px;
            a.StandardBtn {
               &:hover, &:focus {
                  color: @color-c21-gold-ada;
               }
               span.StandardBtnCap {
                  background: none;
               }
            }
         }
      }
      .LaneText {
         img {
            float: none;
            position: relative;
            top: 19px;
            @media(max-width: @screen-tablet-max) {
               top: 45px;
            }

            @media(max-width: @screen-phone-max) {
               top: 0;
            }
         }
      }
   }
   #BodyRightLane {
      display: inline-table;
      vertical-align: top;
      box-sizing: border-box;
      background-color: @color-c21light; // #eae4dc;
      @media(max-width: @screen-tablet-max) {
         width: 768px;
         min-height: 0;
      }
      #desktopContactCard {
         @media(max-width: @screen-tablet-max) {
            display: none !important;
         }
      }
      #contactCard {
         .LaneTitle {
            background-color: @color-c21grey; // #333333;
            border-left: 1px solid #444444;
            font-family: @subhead-font;
            b {
               color: #fff;
               font-size: 18px;
               font-weight: 200;
            }
            color: #fff;
            font-size: 18px;
            font-weight: 200;
         }
         .contactFormBody {
            border-left: 1px solid #c5c0ba;
            box-sizing: border-box;
            background-color: @color-c21light; // #eae4dc;
            @media(max-width: @screen-tablet-max) {
               #sectionContactName {
                  float: left;
               }
               #sectionContactHow {
                  position: absolute;
                  left: 400px;
                  margin-top: 16px;
               }
               #sectionContactContact {
                  float: left;
                  margin-left: 15px;
               }
               #sectionContactComments {
                  float: right;
               }
               #sectionContactSubscribe {
                  position: absolute;
                  left: 400px;
                  margin-top: 72px;
               }
               #sectionContactSubmit {
                  position: absolute;
                  right: 10px;
                  // margin-top: 0;
               }
               #sectionContactHelp {
                  clear: both;
                  top: 180px;
                  padding-top: 10px !important;
                  padding-bottom: 10px;
                  @media(min-width: @screen-tablet-min) {
                     .helptext {
                        text-align: left;
                     }
                  }
               }
            }
            #sectionContactSubmit {
               .BtnContactOffice, .BtnContactAgent, .BtnContactTeam {
                  text-align: center;
                  margin: 10px 0 0 0;
                  width: auto;
                  padding: 1rem;
                  letter-spacing: normal;
                  &:after {
                     font-size: 14px;
                     // text-transform: uppercase;
                     position: relative;
                     top: -9px;
                  }
               }
               .BtnContactOffice {
                  &:after {
                     content: "CONTACT OFFICE";

                  }
               }

               .BtnContactAgent {
                  &:after {
                     content: "CONTACT AGENT";
                  }
               }
               .BtnContactTeam {
                  &:after {
                     content: "CONTACT TEAM";
                  }
               }
            }
         }
      }
   }
}

@media screen and (max-width: @screen-phone-max) {
   html #PageFrame {
      width: 100% !important;
      #PageBody.Standard2LaneRight {
         display: block;
         margin-top: -5px;
         #BodyLeftLane {
            width: 100%;
            #officeLeftLane {
               padding-bottom: 20px;
               max-height: none;
               width: 100%;
               text-align: center;
               display: block;
               #officePicContainer {
                  margin-left: 0;
               }
            }
            #officeRightLane {
               display: block;
               width: 100%;
               text-align: center;
               padding: 20px;
               box-sizing: border-box;
               #contactInfoBlock {
                  display: block;
                  text-align: center;
                  .officeProfile {
                     width: 100%;
                     padding: 0;
                  }
               }
               #areaServed {
                  display: block;
                  padding-right: 0;
                  margin-left: 0;
                  a {
                     margin: 0 auto;
                  }
                  b {
                     display: none;
                  }
                  ul {
                     display: none;
                  }
               }
               #directionsTeaser {
                  display: block;
                  #startAddress {
                     width: 100%;
                     margin-bottom: 20px;
                  }
               }
            }
         }
         #BodyRightLane {
            width: 100%;
            #contactCard {
               #officeContactForm, #agentContactForm {
                  width: 100%;
                  #sectionContactName, #sectionContactContact, #sectionContactComments {
                     float: none;
                     width: 100%;
                     text-align: center;
                     margin-left: 0;
                  }
                  #sectionContactHow, #sectionContactSubmit {
                     position: inherit;
                     text-align: center;
                     margin-top: 10px;
                  }
                  #sectionContactSubscribe {
                     margin-top: 10px;
                     position: inherit;
                     text-align: center;
                     .c21EmailContainer {
                        text-align: center;
                        .contactEmailChkbox, .contactEmailTxtArea {
                           float: none;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
#propertyPaginator, #c21PropertiesSearch {
   @media(min-width: @screen-phone-max) {
      @media(max-width: @screen-tablet-max) {
         display: none !important;
      }
   }
}

.contactListAPropertyPage {
   #PageFrame {
      @media(min-width: @screen-tablet-max) {
         margin-top: 60px;
      }
   }
}

#PageBody.Standard3Lane {
   margin-top: 0px;
   background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAAABCAIAAAAdNtbPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFFmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1sbnM6cGhvdG9zaG9wPSJodHRwOi8vbnMuYWRvYmUuY29tL3Bob3Rvc2hvcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RFdnQ9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZUV2ZW50IyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE4LTAyLTI3VDIwOjUwOjU3LTA3OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxOC0wMi0yN1QyMDo1MToxMi0wNzowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxOC0wMi0yN1QyMDo1MToxMi0wNzowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9maWxlPSJzUkdCIElFQzYxOTY2LTIuMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowZjFmM2Q4Zi1hNzFkLTRkNTQtYjQzYS1kYTFkOTdkMzNiMzgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MGYxZjNkOGYtYTcxZC00ZDU0LWI0M2EtZGExZDk3ZDMzYjM4IiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6MGYxZjNkOGYtYTcxZC00ZDU0LWI0M2EtZGExZDk3ZDMzYjM4Ij4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDowZjFmM2Q4Zi1hNzFkLTRkNTQtYjQzYS1kYTFkOTdkMzNiMzgiIHN0RXZ0OndoZW49IjIwMTgtMDItMjdUMjA6NTA6NTctMDc6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCBDQyAoTWFjaW50b3NoKSIvPiA8L3JkZjpTZXE+IDwveG1wTU06SGlzdG9yeT4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5fQWGJAAAAFUlEQVQokWM8emCXoroewygYBTQDAHm/Are5E78YAAAAAElFTkSuQmCC');
   background-position: right;
   background-repeat: repeat-y;
   background-color: #fff;
   @media(max-width: @screen-tablet-max) {
      //background: none;
   }

   #BodyLeftLane {
      width: 190px;
      padding: 0;
      box-sizing: border-box;
      border-right: 1px solid #bbb;
      @media (max-width: @screen-phone-max) {
         margin-left: -190px !important;
      }
      .LaneTitle {
         background: none;
         font-family: @subhead-font;
      }
   }
   #BodyCenterLane {
      width: 60%;
      box-sizing: border-box;
      @media(max-width: @screen-tablet-max) {
         width: 578px;
      }
      @media(max-width: @screen-phone-max) {
         width: 100%;
      }
      .LaneTitle.TitleColspan {
         font-family: @subhead-font;
         margin-left: -190px;
         z-index: 0;
         @media(max-width: @screen-tablet-max) {
            width: 739px;
         }
         @media(max-width: @screen-phone-max) {
            width: auto;
            margin-left: 0;
         }
      }
      #listContainer {
         #locationSearchBody {
            #innerSearch {
               width: 100%;
               background: #fff;
            }
         }
      }
   }
   #BodyRightLane {
      width: 203px;
      box-sizing: border-box;
      padding: 0;
      .LaneTitle {
         background: none;
         background-color: @color-c21grey; // #333;
         border-left: 1px solid #444444;
         font-family: @subhead-font;
      }
      #agentPaginator {
         .LaneSubTitle {
            border-bottom: 1px solid #bcae9c;
            background: none;
         }
      }
      @media(max-width: @screen-tablet-max) {
         display: none !important;
      }
      #officePaginator, #agentPaginator {
         a.LaneSubtitle {
            span.rightLaneAllLabel {
               color: @black;
               &:hover, &:focus {
                  color: @color-c21-darkest-gold
               }
            }
         }
      }
   }
   #searchNavBottom {
      .BottomPagination {
         left: 0;
         background: none;
      }
   }
}

#PageBody.AgentDetail2LaneRight, #PageBody.OfficeDetail2LaneRight {
   #BodyRightLane {
      .contactEmailChkbox {
         display: inline-block;
         margin-right: 5px;
         vertical-align: top;
         margin-left: 0;
         margin-top: 5px;
         float: none;
      }
      .contactEmailTxtArea {
         float: none;
         display: inline-block;
         margin-left: 5px;
         width: 71%;
         margin-right: 0;
      }
   }
}

#PageBody.AgentDetail3Lane.Standard3Lane {
   @media (max-width: @screen-tablet-max) {
      background: none;
      background-color: #fff;
   }
}

@media(max-width: @screen-phone-max) {
   #PageBody.AgentDetail2LaneRight {
      #BodyLeftLane {
         width: 100% !important;
         text-align: center;
         #agentLeftLane {

            #agentC21LogoContainer {
               margin: 0 auto;
            }
         }
         #agentRightLane {
            #leftBlock {
               padding-right: 0px;
               margin: 0 auto;
            }
            #areaServed {
               #contactInfoBlock {
                  #agentbanneranimate {
                     display: none;
                  }
               }
               .ratingSection {
                  width: auto !important;
                  .reviews {
                     width: auto !important;
                     position: inherit;
                     .reviewCount, .ratingMsg {
                        position: inherit;
                     }
                  }
               }
            }
         }
      }
   }
   #PageBody.AgentDetail3Lane.Standard3Lane {
      background-color: #fff;
      background: none;
      @media(max-width: @screen-tablet-max) {
         background: none;
      }
      #BodyCenterLane {
         #listContainer {
            .secondaryHeader {
               text-align: right;
            }
         }
      }
   }
   #PageBody.Standard1Lane {
      background-color: transparent;
      @media (max-width: @screen-phone-max) {
         padding-top: 310px;
      }
      .contentArea {
         .contentNavigation {
            display: block;
            float: none;
            width: 100%;
            margin-right: 0;
         }
      }
   }
   #PageBody.Standard1Lane.glossaryTermPage {
      @media (max-width: @screen-phone-max) {
         padding-top: 0;
      }
   }
   #PageBody.contentArea {
      .contentNavigation {
         display: block;
         float: none;
         width: 100%;
         margin-right: 0;
      }
      .contentBody {
         width: 100% !important;
         .block_588 {
            display: none;
         }
         .C2msBlock, .C2msBlock_html, .C2msBlock_879 {
            display: none;
         }
      }
   }
}
/* Page Specific Standard3Lane */
body#offlanding, body#agtlanding {
   #PageBody.Standard3Lane, #PageBody.Standard3Lane.locationSearchLayout {
      #BodyRightLane {
         .LaneTitle {
            width: 204px;
         }
         a.PaginatorUp {
            width: 203px;
            border-top: 1px solid #bcae9c;
            .pngIconFallback(92px, 0, '/images/portal/chevron-scroll-top');
            background-color: @color-c21light; // #EAE4DC;
            border-left: 1px solid #C5C0BA;
            &:hover {
               background-color: #fff;
            }
         }
         a.PaginatorDown {
            width: 203px;
            .pngIconFallback(92px, 0, '/images/portal/chevron-scroll-down');
            background-color: @color-c21light; // #EAE4DC;
            border-left: 1px solid #C5C0BA;
            &:hover {
               background-color: #fff;
            }
         }
      }
   }
}

#PageBody.Standard3Lane.locationSearchLayout, #PageBody.Standard3Lane {
   #BodyRightLane {
      .LaneTitle {
         width: 178px;
      }
      a.PaginatorUp {
         width: 203px;
         border-top: 1px solid #bcae9c;
         .pngIconFallback(92px, 0, '/images/portal/chevron-scroll-top');
         background-color: @color-c21light; // #EAE4DC;
         border-left: 1px solid #C5C0BA;
         &:hover {
            background-color: #fff;
         }
      }
      a.PaginatorDown {
         width: 203px;
         .pngIconFallback(92px, 0, '/images/portal/chevron-scroll-down');
         background-color: @color-c21light; // #EAE4DC;
         border-left: 1px solid #C5C0BA;
         &:hover {
            background-color: #fff;
         }
      }
   }
}


#PageBody.Standard1Lane {
   .contentRightLane {
      .StandardBtn {
         position: inherit;
         padding: 0px 10px 0px 10px;
         font-size: 1em;
         text-decoration: none;
         border: 1px solid @redesign-gold;
         margin: 0.5em .5em .5em 0;
         .border-radius(0px);
         cursor: pointer;
         background: @button-blue;
         color: @redesign-grey;
         &:hover, &:focus{
            background: lighten(@gold, 10%);
            color: @white;
         }
         &.pagination{
            background: @grey;
            &:hover, &:focus{
               background: lighten(@grey, 10%);
            }
         }
      }
      .c21Button {
         font-size: 1em;
         text-decoration: none;
         border: none;
         margin: 0.5em .5em .5em 0;
         .border-radius(0px);
         cursor: pointer;
         background: @gold;
         color: @black;
         font-family: @body-font;
         font-weight: normal;
         padding: 4px 8px;
         &:hover, &:focus{
            background: lighten(@gold, 10%);
         }
         &.pagination{
            background: @grey;
            &:hover, &:focus{
               background: lighten(@grey, 10%);
            }
         }
      }
   }
}
#PageBody.Standard1Lane {
   .contentArea {
      .contentBody {
         width: 539px;
         padding-right: 0;
      }
   }
}

@media (max-width: @screen-tablet-max) {
   #PageBody.Standard1Lane {
      .contentArea {
         .contentBody {
            width: 511px;
            border-right: none;
            @media (max-width: @screen-phone-max) {
               width: 100%;
            }
         }
      }
   }
   #PageBody.Standard2LaneLeft {
      background-color: #fff;
      #BodyLeftLane {
         width: 100%;
         min-height: inherit;
         .SideNavigationTitle {
            background: none;
            background-color: @color-c21grey; // #333;
            padding-right: 0;
            position: relative;
            left: -2px;
         }
         .SideNavigation {
            text-align: center;
         }
      }
      #BodyRightLane {
         width: 100%;
         text-align: center;
         .LaneText {
            #generalQuestionForm {
               padding-right: 0;
               .StandardBtn {
                  float: none;
                  margin-right: 0;
               }
            }
         }
      }
   }
}

@media(max-width: @screen-phone-max) {
   body {
      #PageBody .contentBodySection, #franchiseVideo .commContainer, #franchiseVideo .commCenter, #franchiseVideo .mainVideoBg, #franchiseVideo .mainVideo, #franchiseVideo #playerContainer, #franchiseVideo #redundantPlayerContainer_sharing {
         width: 100% !important;
         overflow: hidden;
         height: inherit !important;
      }
   }
   .showRefine {
      #PageFrame {
         .Standard3Lane {
            #BodyLeftLane {
               margin-left: 0 !important;
               border-right: none !important;
            }
         }
      }
   }
}

#PageBody.Standard3Lane:first-child {
   @media(max-width: @screen-phone-max) {
      margin-top: 0;
   }
}

#reviewData {
   #agentReviewsBody {
      width: 984px;
      @media(max-width: @screen-tablet-max) {
         width: 100%;
      }
      @media (min-width: @screen-tablet-max) {
         background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAAABCAIAAAAdNtbPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFFmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1sbnM6cGhvdG9zaG9wPSJodHRwOi8vbnMuYWRvYmUuY29tL3Bob3Rvc2hvcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RFdnQ9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZUV2ZW50IyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE4LTAyLTI3VDIwOjQ5OjA3LTA3OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxOC0wMi0yN1QyMDo0OTo1MC0wNzowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxOC0wMi0yN1QyMDo0OTo1MC0wNzowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9maWxlPSJzUkdCIElFQzYxOTY2LTIuMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyZTE2Nzc3OC1hNTJiLTQzNDYtYThiNC0xMTM4OGMzNmE5OGUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MmUxNjc3NzgtYTUyYi00MzQ2LWE4YjQtMTEzODhjMzZhOThlIiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6MmUxNjc3NzgtYTUyYi00MzQ2LWE4YjQtMTEzODhjMzZhOThlIj4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDoyZTE2Nzc3OC1hNTJiLTQzNDYtYThiNC0xMTM4OGMzNmE5OGUiIHN0RXZ0OndoZW49IjIwMTgtMDItMjdUMjA6NDk6MDctMDc6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCBDQyAoTWFjaW50b3NoKSIvPiA8L3JkZjpTZXE+IDwveG1wTU06SGlzdG9yeT4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7axbQMAAAAFUlEQVQoFWM8emCXoroewygYBTQDAHm/ArecDxRtAAAAAElFTkSuQmCC');
         background-position-x: right;
         background-repeat: repeat-y;
      }
      .LaneTitle.TitleColspan {
         font-family: @subhead-font;
         //max-width: 741px;
      }
      .agentReviews {
         background-color: #fff;
      }
   }
}



#viewAllOfficeAgents {
   font-size: 11px;
}

/* ---- SEO Footer Work ----- */
body.map-view {
   @media (min-width: @screen-phone-max) {
      .footerSeoContainer {
         display: block;
         .footerSeo {
            .topSectionNearbyCity,.topSectionZip, .topSectionNearbyCounty, .rightSection, .topSectionCity {
               width: 100%;
               display: block;
               margin-bottom: 20px;
            }
         }
      }
   }
}
.footerSeoContainer {
   background-position: top;
   background-repeat: repeat-y;
   background-color: #f3f3f3;
   margin-left: 2px;
   margin-right: 2px;
   @media(max-width: @screen-tablet-max) {
      display: none;
   }
   .footerSeo {
      //border-top: 2px solid dimgrey;
      background-position: top;
      background-repeat: no-repeat;
      padding: 20px 38px 15px 38px;
      zoom: 1;
      #showMoreSeoCities {
         display: none;
      }

      .topSectionNearbyCity,.topSectionZip, .topSectionNearbyCounty, .rightSection, .topSectionCity{
         display: inline-block;
         width: 22%;
         margin: 0 auto;
         vertical-align: top;
         h2 {
            text-transform: uppercase;
            font-size: 100%;
            font: inherit;
            font-weight: bold;
            color: #252526;
            display: block;
            margin: 0;
            padding: 0 0 5px 0;
            border: 0;
            vertical-align: baseline;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
         }
         .description {
            color: @dark-text;
            line-height: 12px;
            font-size: 10px;
            > div:nth-child(n+7) {
               a {
                  display: none;
               }
            }
            > div.expandoContainer {
               a {
                  display: block;
               }
            }
            a, label{
               color: #252526;
            }
            a:hover, label:hover {
               color: #A19276;
            }
            fieldset {
               border: none;
            }

            .showMore-toggle {
               display: none;
               ~ label {
                  -webkit-font-smoothing: antialiased;
                  cursor: pointer;
                  color: @footer-show-more-color;
                  font-size: 11px;
                  &:hover, &:focus {
                     color: @color-c21-darkest-gold !important;
                     text-decoration: underline;
                  }
               }
               ~ label:hover {
                  color: @color-c21-darkest-gold;
                  text-decoration: underline;
               }
               ~ label:after {
                  content: "Show More";
               }
            }
            .showMore-toggle:checked ~ label:after{
               content: "Show Less";
            }
            .showMore-toggle:checked ~ div {
               a {
                  display: block;
               }
            }

         }
      }
      .bottomSection {
         padding-top: 10px;
         h2 {
            text-transform: uppercase;
            font-size: 100%;
            font: inherit;
            font-weight: bold;
            color: #898989;
            display: block;
            margin: 0;
            padding: 0 0 5px 0;
            border: 0;
            vertical-align: baseline;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
         }
         a {
            color: @dark-text;
         }
         a:hover {
            color: @black;
         }
      }

      .topSectionNearbyCity {
         min-width: 195px;
      }
      .topSectionZip {
         min-width: 180px;
      }
      .topSectionNearbyCounty {
         min-width: 230px;
      }
      .rightSection {
         width: 270px;
         font-size: 11px;
         color: @dark-text;
         a {
            color: @gold-on-grey;
            &:hover, &:focus {
               text-decoration: underline;
            }
         }
      }
   }
}
body.res {
   #getDirections {
      color: @color-c21grey;
      left: 12px;
      &:hover {
         color: @color-c21-gold-ada !important;
      }
   }
   #officeRightLane {
      #directionsTeaser {
         input {
            width: 423px;
         }
      }
   }
}

#listAPropertyFormHeader {
   #agentWithTablet {
      @media screen and (max-width: @screen-tablet-max ) {
         height: calc(~"100% + 32px");
         top: -17px;
         background-size: auto 100%;
      }
   }
}
#findAHomePropertyDetails {
   label.c21hoverstate {
      margin-left: -3px;
   }
}
//Property-Search
.fahPropertyFeature {
   #propertyAdditional {
      #propertySize {
         width: 245px;
      }
      #listingTypes {
         @media(max-width: @screen-tablet-max) {
            width: 289px;
         }
         width: 310px;
         padding-bottom: 40px;
         padding-left: 20px;
      }
   }
}
a {
   text-decoration: none;
}
#error-page {
   background: white;
   padding: 50px;
   h1 {
      margin-bottom: 20px;
   }
}
//Glossary Page Work
#PageFrame {
   #PageBody.Standard1Lane.glossary {
      #glossaryDefinitionModal {
         margin-top: -46px;
      }
   }
}
@media(min-width: @screen-phone-max) {
   @media(max-width: @screen-tablet-max) {
      #PageFrame {
         #PageBody.Standard1Lane.glossary {
            #glossaryStandingHeader {
               width: 738px;
               #glossaryIndexWrapper {
                  display: none;
               }
            }
            .contentGlossarySection {
               .glossaryEntryWrapper {
                  .glossaryTermsLane {
                     width: 210px;
                  }
               }
            }
            #glossaryDefinitionModal {
               margin-top: -15px;
            }
         }
      }
   }
}

@media(max-width: @screen-phone-max) {
   #PageFrame {
      #PageBody.Standard1Lane.glossary {
         #glossaryStandingHeader {
            width: 100%;
            margin-left: 0;
            border-left: none;
            margin-right: 0;
            border-right: none;
            h1 {
               float: none !important;
            }
            #glossaryPageDescription {
               margin-bottom: 15px;
            }
            .glossarySearch {
               input {
                  outline: none;
               }
            }
            &.clingToTop {
               width: 100%;
               margin-left: -15px;
               position: inherit;
               h1 {
                  margin-left: 15px;
               }
               .glossarySearch {
                  margin-left: 15px
               }
            }
         }
         .contentGlossarySection {
            .glossaryEntryWrapper {
               border-top: none;
               margin-left: 0;
               padding-left: 0;
               .glossaryTermsLane {
                  padding-left: 91px;
               }
            }
         }
         #glossaryDefinitionModal {
            margin-top: -55px;
            .glossaryTermHeader {
               margin-left: -46px;
            }
         }
      }
   }
}
div#PageBody.glossary {
   background: white !important;
}

/* Contact Modal (shared for Esp & Res) */
body.res {
   .propertyCardVariantA {
      display: none;
   }

   .propertyCardVariantB {
      display: block;
   }

   #modalContainer.contactPropertyBox {
      #modalTitle {
         background: @color-c21grey; // #edb23a;
         h1 {
            color: @color-c21-gold;
            font-weight: 500;
         }
      }

      #modalPropertyLeftLane #propertyOfficePic, #modalNonPropLeftLane #nonPropOfficePic, #modalPropertyLeftLane #propertyMultiAgentPic {
         border: none;
      }

      #modalPropertyLeftLane #propertyAgent {
         margin-bottom: 0;
      }

      #modalPropertyRightLane {
         //width: auto;

         #propertyInfo {
            width: 600px;
            height: auto;

            .CenterLaneCardBg {
               background: #f2f0f1;
               height: auto;

               &:hover {
                  background: #f2f0f1;
               }
            }

            .CenterLaneCard {
               color: #000;
               padding: 14px;
               height: auto;
               overflow: auto;

               &:hover {
                  color: #000;
               }

               .cardBasicLeft {
                  float: left;
                  width: 247px;

                  .CardThumb {
                     border: none;
                     position: static;
                     left: initial;
                     top: initial;
                     width: 247px;
                     height: auto;
                     float: none;

                     .landscapeThumbContainer {
                        width: 247px;
                        height: 157px;

                        img {
                           width: 247px;
                           margin: 0;
                        }
                     }

                     .CardFlag {
                        color: #000;
                        text-align: center;
                        padding: 0;
                        font-weight: normal;
                        font-size: 13px;
                        height: 24px;
                        width: 247px;
                        top: 147px;
                        line-height: 24px;
                        background-color: @color-c21-gold;

                        &.FlagComingSoon {
                           color: #fff !important;
                           background-color: #8D312E;
                           padding-top: 3px;
                        }
                     }
                  }

                  .CardPhotoCount {
                     position: static;
                     left: initial;
                     top: initial;
                     line-height: 26px;
                  }
               }

               .cardBasicMiddle {
                  float: left;
                  width: 180px;
                  margin-left: 14px;

                  .CenterLaneCardHeader {
                     position: static;
                     height: auto;

                     h4 {
                        position: static;
                        bottom: auto;
                        left: auto;
                     }
                  }

                  .CardDetails {
                     .primaryDetails {
                        position: static;
                        left: auto;
                        width: auto;
                     }

                     .flagDetails {
                        position: static;
                        left: auto;
                        width: auto;
                        top: auto;
                        color: #000;
                        font-size: 11px;
                        margin-top: 16px;
                     }

                     .secondaryDetails {
                        width: auto;
                        color: #000;
                        text-align: left;
                        position: static;
                        top: auto;
                        right: auto;
                        font-size: 10px;
                        margin-top: 14px;

                        .infoLine {
                           .infoValue {
                              font-size: 12px;
                              margin: 0;
                              color: #000;
                           }
                           .infoLabel {
                              color: #000;
                              width: auto;
                           }
                        }
                     }
                  }
               }

               .cardBasicRight {
                  float: left;
                  width: 112px;
                  margin-left: 14px;

                  .mls-number {
                     font-weight: bold;
                  }

                  .providerAddress {
                     font-size: 11px;
                     line-height: 1.2;
                     margin-top: 10px;
                  }
                  
                  .provider-contact {
                     word-break: break-word;
                  }
               }
            }

            .CardDetails {
               b, a, a:hover {
                  color: #000;
                  word-break: break-word;
               }
            }
         }

         #contactArea {
            b {
               font-weight: normal;
            }

            #contactAreaLeft {
               .contactRadioContainer {
                  font-weight: normal;
               }

               #modalContent label input[type="text"], #modalContent label input[type="email"], #modalContent label input[type="tel"], #modalContent label input[type="password"] {
                  border: 1px solid #f2f0f1;
               }

               #contactCheck {
                  label {
                     color: #999;
                  }
               }
            }

            #contactAreaRight {
               #popupComments {
                  border: none;
                  background-color: #f2f0f1;
                  @media (max-width: @screen-phone-max) {
                     width: 100%;
                  }
               }
               #contactSubmit {
                  background-color: @color-c21-gold; // #edb23a;
                  color: @color-c21grey;
                  padding: 1px 20px;
                  &:hover {
                     color: @white;
                     background: lighten(@color-c21-gold, 10%);
                  }
               }
            }
         }

         .CardPhotoCount {
            color: #000;

            .IconPropertyPhotos16 {
               display: none;
            }
         }
      }

      #modalCloseButton {
         color: @color-c21-gold;
         background: none;

         /*&:after {
            content: "X";
            font-size: 1.3em;
            font-weight: 100;
            padding-left: 10px;
            margin-right: -20px;
            display: inline-block;
            vertical-align: top;
            margin-top: -1px;
         }*/
      }
   }

   #modalContainer.modalSuccessBox.modalPropertySuccessBox {
      #modalTitle {
         background: @color-c21grey; // #edb23a;
         h1 {
            color: @color-c21-gold;
            font-weight: 500;
         }
      }

      #modalCloseButton {
         color: @color-c21-gold;
         background: none;

         &:after {
            content: "X";
            font-size: 1.3em;
            font-weight: 100;
            padding-left: 10px;
            margin-right: -20px;
            display: inline-block;
            vertical-align: top;
            margin-top: -1px;
         }
      }
   }
}

body#pdp, body#srp, body .content-page.franchise{
   #site-footer .footer-disclaimer .footer-row .footer-container .logo{
      margin-top: 0;
   }
}
body#srp .footer-disclaimer .footer-row .footer-container .logo a{
   margin-top: 2em;
}

/***** Rebranding: defaultCSS.css overrides ******/
.RightLaneCard{

   .CardHeader{
      line-height: 1.2em !important;
      color: @gold-on-grey;
   }
   a {
      color: @gold-on-grey !important;
   }
   .CardDetails, .officeCard .phoneNumber {
      color: @gold-on-grey !important;
   }
   &.agentCard .phoneNumber, .RightLaneCard.officeCard .phoneNumber, .CardDetails a.phoneNumber, .CardDetails a.phoneNumber:hover, .CardDetails b {
      color: @gold-on-grey !important;
   }
   &.officeCard {
      height: 118px !important;
      .phoneNumber {
         color: @gold-on-grey !important;
      }
   }
   &.propertyCard {
      height: 139px !important;
   }
   &.agentCard {
      height: 125px !important;
   }
}
#reviewData .schoolReviewContainer.odd{
   background-color: #ece5d9 !important;
}
body.odp{
   .CenterLaneCard {
      &:hover {
         color: #000;
      }
   }
}
#modalContainer{
   #modalCloseButton{
      color: @color-c21-gold !important;
   }
}
#mapPopout .popoutButton{
   color: @color-c21-gold !important;
}

.FTSDropdownBackground .topSuggest .c21AgentsSuggest .infoCount, .FTSDropdownBackground .topSuggest .c21OfficesSuggest .infoCount, .FTSDropdownBackground .topSuggest .forRentSuggest .infoCount, .FTSDropdownBackground .topSuggest .forSaleSuggest .infoCount{
   //color: @color-c21-gold !important;
}
.CardThumb .CardFlag{
   line-height: 21px !important;
}
.FTSCenterLaneCard {
   .primaryDetails {
      top: 42px !important;
      &.office {
         top: 0 !important;
      }
   }

   .FTSCenterLaneCardHeader {
      letter-spacing: normal;
   }
   &.agentCard .agentdesignations {
      top: 134px !important;
   }
}
.modalPropertyInput {
   height: 24px !important;
}
#modalPropertyLeftLane, #modalNonPropLeftLane {
   line-height: 1.45em;
   a {
      color: @gold-on-grey;
   }
}
.FTSOfficeCardHeader.office {
   letter-spacing: normal;
}
/*
#modalNonPropRightLane #contactComments .StandardBtn{
   background: @color-c21-gold;
   &:hover, &:focus{
      background: lighten(@color-c21-gold, 10%);
   }
}
*/

#contactPropertyWindow .CardThumb .CardFlag.FlagVirtualOpenHouse {
   top: 138px !important;
   height: 18px !important;
}
.FTSCenterLaneCard .CardThumb .CardFlag.FlagVirtualOpenHouse {
   bottom: 0 !important;
   padding-right: 36px;
}
.CardThumb .CardFlag.FlagVirtualOpenHouse {
   margin-left: 5px;
   margin-top: 5px;
   border-radius: 11px;
   color: #000000;
   background-color: #beaf87;
   width: auto;
   //top: 0;
   bottom: auto;
   .voh-date {
      text-transform: lowercase;
      display: none;
   }
}

/* Disable the submit button */
#modalContainer #contactSubmit,
#modalContainer .btnSubmit,
#contactCard .btnSubmit,
#listAPropertyLayoutInner #listAPropertyForm .btnSubmit,
#listAPropertyLayoutInner #listAPropertyForm .formAction a {
   &.disabled {
      background-color: @light-grey;
      cursor: none;
      &:hover, &:focus {
         background-color: @light-grey;
         cursor: none;
      }
   }
}

.freetextsearchLabelNoData {
   color: #434343 !important;
}
#dropdownResults {
   #innerSearch .cell.disabled a, #innerSearch .cell.disabled a:hover {
      color: #696969 !important;
   }
}
.FTSCenterLaneCard {
   a {
      color: #6d5e44 !important;
   }
   a:hover, a:focus {
      text-decoration: underline !important;
   }
   .bottomRightDetails {
      color: #696969 !important;
   }
}
.FTSCenterLaneCardHeader {
   color: #6d5e44;
}

body.cms-content {
   #site-footer .footer-disclaimer .footer-row #cms-page-disclaimer {
      display: block;
   }
}
