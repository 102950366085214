/****   Font: Oakes
URL: 		https://www.youworkforthem.com/font/T7454/oakes-typeface
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2016 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	November 13, 2017
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
****/

@font-face {
  font-family: 'Oakes';
  src: url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.eot');
  src: url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.woff2') format('woff2'),
  url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.woff') format('woff'),
  url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.ttf') format('truetype'),
  url('/css/c21/fonts/oakes/oakes-light/webfonts/oakes-light.svg#youworkforthem') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Oakes';
  src: url('/css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.eot');
  src: url('/css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.woff2') format('woff2'),
  url('/css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.woff') format('woff'),
  url('css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.ttf') format('truetype'),
  url('/css/c21/fonts/oakes/oakes-medium/webfonts/oakes-medium.svg#youworkforthem') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oakes';
  src: url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.eot');
  src: url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.woff2') format('woff2'),
  url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.woff') format('woff'),
  url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.ttf') format('truetype'),
  url('/css/c21/fonts/oakes/oakes-regular/webfonts/oakes-regular.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Oakes';
  src: url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.eot');
  src: url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.woff2') format('woff2'),
  url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.woff') format('woff'),
  url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.ttf') format('truetype'),
  url('/css/c21/fonts/oakes/oakes-bold/webfonts/oakes-bold.svg#youworkforthem') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Oakes';
  src: url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.eot');
  src: url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.woff2') format('woff2'),
  url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.woff') format('woff'),
  url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.ttf') format('truetype'),
  url('/css/c21/fonts/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: italic;
}

/****   Font: Typold   ****/
@font-face {
  font-family: 'Typold';
  src: url('/css/c21/fonts/typold/Typold_Regular_WOFFWOFF2/Typold-Regular.woff2') format('woff2'),
  url('/css/c21/fonts/typold/Typold_Regular_WOFFWOFF2/Typold-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Typold';
  src: url('/css/c21/fonts/typold/Typold_Book500_WOFFWOFF2/Typold-Book500.woff2') format('woff2'),
  url('/css/c21/fonts/typold/Typold_Book500_WOFFWOFF2/Typold-Book500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Typold';
  src: url('/css/c21/fonts/typold/Typold_Bold_WOFF/Typold-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}
/****   Font: Typold Condensed  ****/
@font-face {
  font-family: 'TypoldCondensed';
  src: url('/css/c21/fonts/typold/Typold_Condensed_WOFF2/TypoldCondensed-Regular.woff2') format('woff2'),
  url('/css/c21/fonts/typold/Typold_Condensed_WOFF/TypoldCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TypoldCondensed';
  src: url('/css/c21/fonts/typold/Typold_Condensed_WOFF2/TypoldCondensed-Bold.woff2') format('woff2'),
  url('/css/c21/fonts/typold/Typold_Condensed_WOFF/TypoldCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/****   Font: Typold Extended  ****/
@font-face {
  font-family: 'TypoldExtended';
  src: url('/css/c21/fonts/typold/Typold_Extended_WOFF/TypoldExtended-Medium.woff') format('woff'),
  url('/css/c21/fonts/typold/Typold_Extended_WOFF2/TypoldExtended-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/****   Font: Barlow   ****/
@font-face {
  font-family: 'BarlowSemiCondensed';
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Light.eot');
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Light.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/barlow/woff2/BarlowSemiCondensed-Light.woff2') format('woff2'),
  url('/css/c21/fonts/barlow/woff/BarlowSemiCondensed-Light.woff') format('woff'),
  url('/css/c21/fonts/barlow/ttf/BarlowSemiCondensed-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'BarlowSemiCondensed';
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Medium.eot');
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Medium.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/barlow/woff2/BarlowSemiCondensed-Medium.woff2') format('woff2'),
  url('/css/c21/fonts/barlow/woff/BarlowSemiCondensed-Medium.woff') format('woff'),
  url('/css/c21/fonts/barlow/ttf/BarlowSemiCondensed-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BarlowSemiCondensed';
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Bold.eot');
  src: url('/css/c21/fonts/barlow/eot/BarlowSemiCondensed-Bold.eot?#iefix') format('embedded-opentype'),
  url('/css/c21/fonts/barlow/woff2/BarlowSemiCondensed-Bold.woff2') format('woff2'),
  url('/css/c21/fonts/barlow/woff/BarlowSemiCondensed-Bold.woff') format('woff'),
  url('/css/c21/fonts/barlow/ttf/BarlowSemiCondensed-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}